import { editMainFieldset, editAddressFieldset } from './fieldset';

export default {
  title: 'Modifica Cliente',
  actions: {
    fetch: {
      endpoint: 'customers/{id}',
      method: 'GET',
      params: {
        expand: 'home_address__province,user'
      }
    }
  },
  elements: [
    {
      type: 'editForm',
      config: {
        actions: {
          save: {
            endpoint: 'customers/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [editMainFieldset]
      }
    },
    {
      type: 'editForm',
      config: {
        actionContext: {
          id: 'homeAddressId'
        },
        actions: {
          save: {
            endpoint: 'addresses/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [editAddressFieldset]
      }
    }
  ]
};
