import React from 'react';

import { Switch, FormControlLabel } from '@material-ui/core';

export default React.forwardRef(function SwitchField(
  { name, label, value, onChange },
  ref
) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={!!value}
          onChange={event => {
            onChange(event.target.checked);
          }}
          color="secondary"
          name={name}
        />
      }
      label={label}
    />
  );
});
