import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { get } from 'lodash';
import TruncateMarkup from 'react-truncate-markup';
import parse from 'html-react-parser';

const HtmlContent = ({ data, field, lines = 3 }) => {
  const value = get(data, field);

  const [shouldTruncate, setTruncate] = useState(true);

  const readMoreEllipsis = (
    <Typography>
      <Button
        onClick={() => setTruncate(false)}
        variant="outlined"
        size="small"
      >
        Read more
      </Button>
    </Typography>
  );

  const readLess = (
    <Typography>
      <Button onClick={() => setTruncate(true)} variant="outlined" size="small">
        Read less
      </Button>
    </Typography>
  );

  const parsedContent = value ? parse(value) : null;

  const content = shouldTruncate ? (
    <TruncateMarkup lines={lines} ellipsis={readMoreEllipsis}>
      <Typography component="div">{parsedContent}</Typography>
    </TruncateMarkup>
  ) : (
    <div>
      <Typography component="div">
        {parsedContent} {readLess}
      </Typography>
    </div>
  );

  return content;
};

export default HtmlContent;
