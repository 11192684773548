import { useState, useEffect, useCallback } from 'react';
import useAxios, { configure } from 'axios-hooks';
import { get } from 'lodash';

import { expandParams } from 'src/dataUtils';
import useApiClient from 'src/hooks/useApiClient';
import { extract } from 'src/dataUtils';

const useTableView = ({ config, resourceId }) => {
  const initialTab =
    config.tabs && config.tabs.length ? config.tabs[0].id : null;

  const [currentTab, setCurrentTab] = useState(initialTab);
  const [resultToDelete, setResultToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({});

  const [selectedRows, setSelectedRows] = useState([]);

  const [sortBy, setSortBy] = useState(null);
  const [sortByDir, setSortByDir] = useState(null);
  const [selectAllResults, setSelectAllResults] = useState(false);

  const { client } = useApiClient();

  const defaultParams = config.defaultParams
    ? expandParams(config.defaultParams, { resourceId })
    : {};

  configure({ axios: client });

  const [{ loading: bulkActionLoading }, executeBulkAction] = useAxios(
    {},
    { manual: true }
  );

  const [{ loading: rowActionLoading }, executeRowAction] = useAxios(
    {},
    { manual: true }
  );

  const handleToggleRowSelection = rowId => {
    const newSelection = [...selectedRows];
    const existingIndex = newSelection.indexOf(rowId);

    if (existingIndex > -1) {
      newSelection.splice(existingIndex, 1);
    } else {
      newSelection.push(rowId);
    }

    setSelectedRows(newSelection);
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const onDelete = result => {
    setResultToDelete(result);
  };

  const handleDeleteCancel = () => {
    setResultToDelete(null);
  };

  const handleRowAction = ({ action, result }) => {
    executeRowAction(expandParams(action.request || {}, { id: result.id }));
  };

  const handleBulkAction = async ({ request }, { data }) => {
    await executeBulkAction({
      url: request.endpoint,
      method: request.method,
      params: appliedFilters ? appliedFilters : undefined,
      data: {
        ids: !selectAllResults ? selectedRows : undefined,
        formData: data
      }
    });

    if (config.tabs && config.tabs.length) {
      setCurrentTab(config.tabs[0].id);
    }

    setSelectedRows([]);
    setResultToDelete(null);

    executeFetch({
      params: {
        ...defaultParams,
        ...currentTabParams,
        ...appliedFilters
      }
    });
  };

  const handleDeleteItem = async resultToDelete => {
    await executeDelete({
      method: 'DELETE',
      url: `${config.url}/${resultToDelete.id}`
    });
    setCurrentPage(0);

    executeFetch({
      params: {
        ...defaultParams,
        ...currentTabParams,
        ...appliedFilters,
        page: currentPage + 1
      }
    });
  };

  const handleDeleteConfirm = async () => {
    await handleDeleteItem(resultToDelete);
    setResultToDelete(null);
  };

  const [{ data: fetchResult, loading: fetchLoading }, executeFetch] = useAxios(
    {
      url: config.url
    },
    { manual: true }
  );

  const resultsPath = get(config, 'response.mappers.list');

  const processedResults = !fetchResult
    ? []
    : resultsPath
    ? extract(fetchResult, resultsPath)
    : fetchResult;

  const handleToggleSelectAll = select => {
    if (select) {
      setSelectedRows(processedResults.map(row => row.id)); // TODO: get key dinamically
      return;
    }
    setSelectedRows([]);
  };

  const handleToggleSelectAllRecords = select => {
    setSelectAllResults(select);
  };

  let paginationInfo = null;

  if (fetchResult && config.pagination) {
    paginationInfo = {
      count: extract(fetchResult, config.pagination.mappers.count),
      perPage: extract(fetchResult, config.pagination.mappers.perPage)
    };
  }

  const [, executeDelete] = useAxios(
    {
      url: config.url,
      method: 'DELETE'
    },
    { manual: true }
  );

  let currentTabParams = {};

  if (config.tabs && currentTab) {
    let currentTabConfig = config.tabs.find(t => t.id === currentTab);
    currentTabParams = currentTabConfig ? currentTabConfig.requestParams : {};
  }

  useEffect(() => {
    let sort;

    if (sortBy) {
      const sortCol = config.columns.find(col => col.field === sortBy);
      const sortParam = get(sortCol, 'sortable.param', sortBy);

      sort = `${sortByDir === 'desc' ? '-' : ''}${sortParam}`;
    }

    const finalParams = {
      ...defaultParams,
      ...currentTabParams,
      ...appliedFilters,
      page: currentPage + 1,
      ...(sort ? { sort } : {})
    };

    executeFetch({
      params: finalParams
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, executeFetch, currentTab, sortBy, sortByDir]);

  const applyFilters = useCallback(filterParams => {
    setAppliedFilters({ ...appliedFilters, ...filterParams });
    executeFetch({
      params: {
        ...defaultParams,
        ...appliedFilters,
        ...currentTabParams,
        ...filterParams,
        page: 1
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilters = useCallback(() => {
    setAppliedFilters({});
    executeFetch({
      params: {
        ...defaultParams,
        ...currentTabParams,
        page: 1
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applySortBy = useCallback((colName, dir) => {
    setSortBy(colName);
    setSortByDir(dir);
  }, []);

  const pagination = paginationInfo
    ? {
        count: paginationInfo.count || 0,
        page: currentPage,
        limit: paginationInfo.perPage,
        onChangePage: (event, page) => {
          setCurrentPage(page);
        }
      }
    : null;

  return {
    currentTab,
    resultToDelete,
    handleTabsChange,
    handleDeleteConfirm,
    handleDeleteCancel,
    handleRowAction,
    onDelete,
    pagination,
    appliedFilters,
    sortBy,
    sortByDir,
    handleToggleRowSelection,
    handleToggleSelectAll,
    selectedRows,
    handleBulkAction,
    bulkActionLoading,
    rowActionLoading,
    handleToggleSelectAllRecords,
    selectAllResults,
    actions: {
      applyFilters,
      resetFilters,
      applySortBy,
      fetch: {
        execute: executeFetch,
        loading: fetchLoading,
        results: processedResults
      }
    }
  };
};

export default useTableView;
