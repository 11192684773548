import React from 'react';
import { get } from 'lodash';
import { Breadcrumbs, Chip } from '@material-ui/core';

const Path = ({ data, field, itemNamePath }) => {
  const list = get(data, field, []);

  return (
    <Breadcrumbs separator="/">
      {list.map((item, index) => {
        const itemLabel = get(item, itemNamePath);

        return (
          <Chip key={index} label={itemLabel} variant="outlined" size="small" />
        );
      })}
    </Breadcrumbs>
  );
};

export default Path;
