import React, { useState, useEffect } from 'react';
import SortableTreeCmp from 'react-sortable-tree';

import 'react-sortable-tree/style.css';
import NodeRenderer from './NodeRenderer';
import Form from 'src/components/forms/Form';
import FormField from '../../FormField';

const SortableTree = React.forwardRef(function SortableTree(
  { form, value, name },
  ref
) {
  console.log('vvv', value);

  const [treeData, setTreeData] = useState(value);

  const onChange = data => {
    console.log('tree', data);
    setTreeData(data);
  };

  useEffect(() => {
    console.log('SET TREE CALLED');
    setTreeData(value);
  }, [value]);

  // const fField = [{ label: 'Title', name: 'title', size: 'small' }];

  const renderNodeContent = ({ node, path }) => {
    const fieldPath = [name, ...path].join('.');

    const field = { label: 'Title', name: 'title', size: 'small' };

    console.log('node', fieldPath, node);

    // const renderedFields = fField.map(field => );
    const fCmp = (
      <FormField
        {...field}
        defaultValue={''}
        parentRecord={null}
        key={node.id}
      />
    );

    return (
      <React.Fragment key={fieldPath}>
        A {node.title} - {fieldPath}
        <Form initialValues={{ ...node }} fieldset={fCmp} key={node.id} />
      </React.Fragment>
    );
  };

  const generateNodeProps = () => {
    return {
      nodeContent: renderNodeContent
    };
  };

  return (
    <div style={{ height: 600 }}>
      <SortableTreeCmp
        treeData={treeData}
        onChange={onChange}
        nodeContentRenderer={NodeRenderer}
        generateNodeProps={generateNodeProps}
        rowHeight={() => 100}
      />
    </div>
  );
});

export default SortableTree;
