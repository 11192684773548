import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import FormPage from 'src/components/pages/FormPage';
import { get } from 'lodash';

import ViewElements from '../ViewElements';

const CreateView = ({ config, resourceSlug }) => {
  const history = useHistory();
  const { appName } = useParams();

  const breadCrumbs = [
    { label: config.title, to: `/${appName}/app/${resourceSlug}/list` }
  ];

  const onSaved = responseData => {
    if (responseData) {
      const resourceId = get(
        responseData,
        get(config, 'afterSave.redirect.resourceIdPath', 'data.id')
      );

      if (resourceId) {
        history.push(`/${appName}/app/${resourceSlug}/edit/${resourceId}`);
      }
    }
  };

  return (
    <FormPage title={config.title} breadcrumbs={breadCrumbs}>
      <ViewElements
        resourceSlug={resourceSlug}
        elements={config.elements}
        onSaved={onSaved}
      />
    </FormPage>
  );
};

export default CreateView;
