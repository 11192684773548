import * as yup from 'yup';
import { supplierSelect } from '../supplier/shared';
import { membershipPriceSelect } from '../membershipPrice/shared';

export const main = {
  title: 'Main info',
  initialValues: {},
  schema: yup.object().shape({
    supplier_id: yup.number().required(),
    membership_price_id: yup.number().required(),
    trial_days: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === '' ? null : value
      )
      .positive()
      .integer()
  }),

  sections: [
    {
      fields: [
        {
          ...supplierSelect,
          label: 'Supplier'
        },
        {
          ...membershipPriceSelect,
          label: 'Membership Pricing'
        },
        {
          label: 'Coupon',
          name: 'membership_coupon_id',
          type: 'lazySelect',
          displayAttr: 'name',
          selectedOptionAttr: 'coupon',
          request: {
            endpoint: 'membership-coupons',
            dataPath: 'data'
          },
          selectedWidget: {
            widget: 'card',
            main: 'name'
          },
          optionWidget: {
            widget: 'card',
            main: 'name'
          }
        },
        {
          label: 'Trial days',
          name: 'trial_days',
          type: 'number'
        },
        {
          label: 'Start subscription billing from',
          name: 'backdate_start_date',
          type: 'date'
        },
        {
          label: 'Notes',
          name: 'notes',
          multiline: true
        }
      ]
    }
  ]
};
