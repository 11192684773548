import * as yup from 'yup';

export const main = {
  title: 'Main info',
  initialValues: {},
  schema: yup.object().shape({}),

  sections: [
    {
      title: 'Main details',
      fields: [
        {
          label: 'Product Name',
          name: 'name'
        },
        {
          label: 'Product Description',
          name: 'description',
          multiline: true
        },
        {
          label: 'Stripe identifier',
          name: 'external_id'
        },
        {
          label: 'Active',
          name: 'active',
          type: 'switch'
        }
      ]
    },
    {
      title: 'Pricings',
      fields: [
        {
          name: 'prices',
          type: 'hasMany',
          includeIds: true,
          orderable: false,
          layout: 'verticalList',
          fields: [
            {
              label: 'Prezzo',
              name: 'amount',
              span: 4
            },
            {
              label: 'Currency',
              name: 'currency',
              type: 'select',
              span: 2,
              options: [
                { label: 'Euro', value: 'EUR' },
                { label: 'USD', value: 'USD' },
                { label: 'GBP', value: 'GBP' }
              ]
            },
            {
              label: 'Billing period',
              name: 'billing_period',
              type: 'select',
              options: [
                { label: 'Monthly', value: 'monthly' },
                { label: 'yearly', value: 'yearly' }
              ]
            },
            {
              label: 'Stripe id',
              name: 'external_id'
            }
          ]
        }
      ]
    }
  ]
};
