export default {
  label: 'Background color',
  name: 'bg_color',
  type: 'radioGroup',
  row: true,
  options: [
    { label: 'White', value: 'bg-white' },
    { label: 'Secondary', value: 'bg-secondary' },
    { label: 'Secondary light', value: 'bg-secondary-light' }
  ]
};
