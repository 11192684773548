import React, { createContext, useRef, useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';

import { getAppConfig } from 'src/generics/dataUtils';
import { useParams } from 'react-router-dom';

export const ApiClientContext = createContext({});

export const ApiClientProvider = ({ children }) => {
  const { appName } = useParams();
  const [clientInit, setClientInit] = useState(false);
  const appConfig = getAppConfig(appName);
  const clientRef = useRef();

  useEffect(() => {
    console.log('Creating API client');
    clientRef.current = axios.create({
      baseURL: appConfig.api.baseUrl,
      timeout: 20000,
      paramsSerializer: params => qs.stringify(params, { format: 'RFC1738' })
    });

    setClientInit(true);
  }, []);

  if (!clientInit || !clientRef.current) {
    return null;
  }

  return (
    <ApiClientContext.Provider value={{ client: clientRef.current }}>
      {children}
    </ApiClientContext.Provider>
  );
};

export const ApiClientConsumer = ApiClientContext.Consumer;

export default ApiClientProvider;
