import create from './create';
import edit from './edit';
import list from './list';
// import detail from './detail';

export default {
  create,
  edit,
  list
  // detail
};
