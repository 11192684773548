import * as yup from 'yup';
import { relatedProductPicker } from '../product/shared';

export const createFieldset = {
  title: 'Dettagli',
  initialValues: {
    name: '',
    description: ''
  },
  schema: yup.object().shape({}),
  sections: [
    {
      title: 'Dati principali',
      fields: [
        {
          label: 'Prodotto',
          type: 'relatedPicker',
          name: 'product',
          title: 'Seleziona un prodotto',
          viewConfig: relatedProductPicker
        },
        {
          label: 'Title',
          name: 'title'
        },
        {
          label: 'Valida dal',
          name: 'validityRange.lower',
          span: 6,
          type: 'datetime-local'
        },
        {
          label: 'Al',
          name: 'validityRange.upper',
          span: 6,
          type: 'datetime-local'
        },
        {
          label: 'Description',
          name: 'description'
        },
        {
          label: 'Pubblicata',
          name: 'published',
          type: 'switch'
        }
      ]
    }
  ]
};

export const editFieldset = {
  title: 'Dettagli',
  initialValues: {
    name: '',
    description: ''
  },
  schema: yup.object().shape({}),
  sections: [
    {
      title: 'Dati principali',
      fields: [
        {
          label: 'Prodotto',
          type: 'relatedPicker',
          name: 'product',
          title: 'Seleziona un prodotto',
          initialValuePath: 'product',
          viewConfig: relatedProductPicker
        },
        {
          label: 'Title',
          name: 'title'
        },
        {
          label: 'Valida dal',
          name: 'validityRange.lower',
          span: 6,
          type: 'datetime-local'
        },
        {
          label: 'Al',
          name: 'validityRange.upper',
          span: 6,
          type: 'datetime-local'
        },
        {
          label: 'Description',
          name: 'description'
        },
        {
          label: 'Pubblicata',
          name: 'published',
          type: 'switch'
        }
      ]
    }
  ]
};
