import * as yup from 'yup';

const relatedUserPicker = {
  type: 'dataTable',
  url: 'users',
  defaultParams: {
    expand: 'customer,business__logo'
  },
  response: {
    mappers: {
      list: 'results'
    }
  },
  tabs: [],
  selection: {
    multiple: false,
    valueSelector: 'id',
    selectedWidget: {
      widget: 'card',
      main: 'email'
      // image: {
      //   field: 'listing_image_url',
      //   width: 75,
      //   height: 75
      // }
    }
  },
  pagination: {
    mappers: {
      count: 'count'
    }
  },
  columns: [
    {
      label: 'Email',
      field: 'email'
    },
    {
      label: 'Business',
      widget: 'card',
      main: 'business.name',
      image: {
        field: 'business.logo.file',
        width: 50,
        height: 50
      }
    },
    {
      label: 'Customer',
      widget: 'card',
      main: 'customer.firstName'
    },
    {
      label: 'Created',
      field: 'created_at',
      widget: 'datetime',
      sortable: true
    }
  ]
};

export const createFieldset = {
  initialValues: {},
  schema: yup.object().shape({
    bowlType: yup.string().required(),
    fromUser: yup.mixed().required(),
    toUser: yup.mixed().required()
    // codename: yup.string().required()
  }),
  sections: [
    {
      title: 'Dati principali',
      fields: [
        {
          label: 'Tipologia Bowl',
          name: 'bowlType',
          type: 'lazySelect',
          displayAttr: 'name',
          valueAttr: 'id',
          selectedOptionAttr: 'name',
          request: {
            endpoint: 'bowl-types'
          }
        },
        {
          label: "Dall'utente",
          type: 'relatedPicker',
          name: 'fromUser',
          title: 'Select a user',
          viewConfig: relatedUserPicker
        },
        {
          label: "All'utente",
          type: 'relatedPicker',
          name: 'toUser',
          title: 'Select a user',
          viewConfig: relatedUserPicker
        }
      ]
    }
  ]
};
