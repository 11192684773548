import slideTypes from '../slideTypes';

export default {
  value: 'hero_carousel',
  label: 'Hero Carousel',
  fields: [
    {
      name: 'items',
      type: 'hasMany',
      includeIds: false,
      collapsible: true,
      layout: 'verticalList',
      typeSelector: {
        typeFieldName: 'block_type',
        addLabel: 'Add new slide:',
        options: [
          slideTypes.hero,
          slideTypes.hero_big_text,
          slideTypes.hero_text_center,
          slideTypes.hero_with_actions,
          slideTypes.main_hero
        ]
      }
    }
  ]
};
