import * as fieldset from './fieldset';

export default {
  title: 'Edit Supplier',
  elements: [
    {
      title: 'Main',
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'suppliers/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'suppliers/{id}',
            method: 'PUT'
          }
        },
        fieldsets: [fieldset.main]
      }
    },
    {
      title: 'Account',
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'suppliers/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'suppliers/{id}/reset-password',
            method: 'POST'
          }
        },
        fieldsets: [fieldset.account]
      }
    }
  ]
};
