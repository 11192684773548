import fieldset from './fieldset';

export default {
  title: 'Add Category',
  elements: [
    {
      type: 'createForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'categories/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'categories',
            method: 'POST'
          }
        },
        fieldsets: [fieldset]
      }
    }
  ]
};
