import * as yup from 'yup';

export default {
  title: 'Main info',
  schema: yup.object().shape({
    name: yup.string().required(),
    supplier_id: yup.mixed().required(),
    images: yup.array().of(
      yup.object().shape({
        path: yup.string().required(),
        description: yup.string().nullable()
      })
    )
  }),
  initialValues: {
    name: '',
    supplier_id: null,
    sku: '',
    published: false,
    description: '',
    category_ids: [],
    images: []
  },
  cols: {
    main: {
      sections: [
        {
          title: 'Details',
          fields: [
            {
              label: 'Name',
              name: 'name'
            },
            {
              label: 'SKU',
              name: 'sku',
              span: 6
            },
            {
              label: 'Description',
              name: 'description',
              type: 'richText'
            }
          ]
        },
        {
          title: 'Images',
          fields: [
            {
              name: 'images',
              type: 'hasMany',
              includeIds: true,
              orderable: false,
              layout: 'horizontalList',
              cellHeight: 350,
              fields: [
                {
                  name: 'path',
                  type: 'image',
                  request: {
                    endpoint: 'image-uploads',
                    method: 'POST'
                  },
                  response: {
                    previewUrlPath: 'url',
                    valuePath: 'path'
                  },
                  initialUrlAttr: 'url',
                  span: 4,
                  width: 140,
                  height: 140
                }
              ]
            }
          ]
        },
        {
          title: 'Product Types',
          fields: [
            {
              name: 'types',
              type: 'hasMany',
              includeIds: true,
              layout: 'verticalList',
              fields: [
                {
                  label: 'Product Subtype',
                  name: 'type',
                  type: 'select',
                  options: [
                    { label: 'Brand', value: 'brand' },
                    { label: 'Private Label', value: 'private_label' }
                  ]
                },
                {
                  label: 'Published',
                  name: 'published',
                  type: 'switch'
                },
                {
                  label: 'Wholesale price',
                  name: 'price',
                  type: 'number',
                  span: 4
                },
                {
                  label: 'Currency',
                  name: 'price_currency_code',
                  type: 'select',
                  span: 2,
                  options: [
                    { label: 'Euro', value: 'EUR' },
                    { label: 'USD', value: 'USD' },
                    { label: 'GBP', value: 'GBP' }
                  ]
                },
                {
                  label: 'Size range',
                  name: 'size_range'
                },
                {
                  label: 'Min. order quantity',
                  name: 'min_order_quantity',
                  type: 'number'
                },
                {
                  label: 'Season is continuative',
                  name: 'season_continuative',
                  type: 'switch',
                  showIf: { field: 'type', value: 'brand' }
                },
                {
                  label: 'Season name',
                  name: 'season_name',
                  showIf: { field: 'season_continuative', value: false }
                },
                {
                  label: 'Delivery time',
                  name: 'delivery_time',
                  showIf: { field: 'type', value: 'brand' }
                },
                {
                  label: 'Deadline for acceptance',
                  name: 'deadline_for_acceptance',
                  showIf: { field: 'type', value: 'brand' }
                },
                {
                  label: 'Estimated delivery',
                  name: 'estimated_delivery',
                  showIf: { field: 'type', value: 'private_label' }
                }
              ]
            }
          ]
        }
      ]
    },
    secondary: {
      sections: [
        {
          title: 'Supplier',
          fields: [
            {
              name: 'supplier_id',
              type: 'lazySelect',
              displayAttr: 'screen_name',
              selectedOptionAttr: 'supplier',
              request: {
                endpoint: 'suppliers',
                params: { sort: 'screen_name', nopagination: true },
                dataPath: 'data'
              },
              selectedWidget: {
                widget: 'card',
                main: 'screen_name',
                image: {
                  field: 'logo_url',
                  width: 40,
                  height: 40
                }
              },
              optionWidget: {
                widget: 'card',
                main: 'screen_name',
                image: {
                  field: 'logo_url',
                  width: 30,
                  height: 30
                }
              }
            }
          ]
        },
        {
          title: 'Status',
          fields: [
            {
              label: 'Published',
              name: 'published',
              type: 'switch'
            }
          ]
        },
        {
          title: 'Categories',
          fields: [
            {
              name: 'category_ids',
              type: 'tree',
              response: {
                dataPath: 'data'
              },
              actionParams: {
                url: 'categories',
                params: {
                  mode: 'tree'
                }
              }
            }
          ]
        }
      ]
    }
  }
};
