import * as fieldset from './fieldset';

export default {
  title: 'Edit Membership Product',
  elements: [
    {
      title: 'Main',
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'membership-products/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'membership-products/{id}',
            method: 'PUT'
          }
        },
        fieldsets: [fieldset.main]
      }
    }
    // {
    //   title: 'Account',
    //   type: 'editForm',
    //   config: {
    //     actions: {
    //       fetch: {
    //         endpoint: 'suppliers/{id}',
    //         method: 'GET',
    //         dataPath: 'data'
    //       },
    //       save: {
    //         endpoint: 'suppliers/{id}/reset-password',
    //         method: 'POST'
    //       }
    //     },
    //     fieldsets: [fieldset.account]
    //   }
    // }
  ]
};
