import Schema, {
  SchemaInput,
  string,
  number,
  object,
  boolean
} from 'computed-types';

// enum ColumnWidgetType {
//   card,
//   datetime
// }

const Slot = Schema.either(string, { widget: string });
const Field = Schema.either(string, { type: string, template: string });

const ThumbWidgetSchema = Schema({
  field: Field,
  width: number.optional(),
  height: number.optional()
});

const CardWidgetSchema = Schema({
  main: Slot.optional(),
  image: ThumbWidgetSchema.optional(),
  secondary: Slot.optional(),
  tertiary: Slot.optional()
});

const PathWdgetSchema = Schema({
  field: Field,
  itemNamePath: string
});

const IdentifierWidgetSchema = Schema({
  field: Field
});

const DateTimeWidgetSchema = Schema({
  field: Field
});

const LogoWidgetSchema = Schema({
  field: Field
});

const PaginationSchema = Schema({
  mappers: object
});

const ColumnWidgetTypes = Schema.either(
  CardWidgetSchema,
  ThumbWidgetSchema,
  IdentifierWidgetSchema
);

const ColumnSchema = Schema({
  label: string.optional(),
  sortable: boolean.optional(),
  align: Schema.either(
    'center' as const,
    'left' as const,
    'right' as const
  ).optional(),
  field: string.optional(),
  widget: ColumnWidgetTypes.optional()
});

type Column = SchemaInput<typeof ColumnSchema>;
type ThumbWidget = SchemaInput<typeof ThumbWidgetSchema>;
type CardWidget = SchemaInput<typeof CardWidgetSchema>;
type IdentifierWidget = SchemaInput<typeof IdentifierWidgetSchema>;
type PathWidget = SchemaInput<typeof PathWdgetSchema>;
type LogoWidget = SchemaInput<typeof LogoWidgetSchema>;
type DateTimeWidget = SchemaInput<typeof DateTimeWidgetSchema>;

type Pagination = SchemaInput<typeof PaginationSchema>;

export const card = ({ main, image, secondary, tertiary }: CardWidget) => ({
  widget: 'card',
  main,
  image,
  secondary,
  tertiary
});

export const column = ({ label, sortable, ...rest }: Column) => ({
  label,
  sortable,
  ...rest
});

export const identifierLabel = ({ field }: IdentifierWidget) => ({
  widget: 'identifierLabel',
  field
});

export const path = ({ field, itemNamePath }: PathWidget) => ({
  widget: 'path',
  itemNamePath,
  field
});

export const thumb = ({ field, width, height }: ThumbWidget) => ({
  widget: 'thumb',
  field,
  width,
  height
});

export const logo = ({ field }: LogoWidget) => ({
  widget: 'logo',
  field
});

export const dateTime = ({ field }: DateTimeWidget) => ({
  widget: 'datetime',
  field
});

///

export const pagination = ({ mappers }: Pagination) => ({
  mappers: mappers
});
