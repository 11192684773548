import { supplierListFilters } from './shared';

export const relatedSupplierPicker = {
  type: 'dataTable',
  url: 'suppliers',
  defaultParams: {
    sort: '-created_at'
  },
  response: {
    mappers: {
      list: 'data'
    }
  },
  filters: supplierListFilters,
  selection: {
    multiple: false,
    valueSelector: 'id',
    selectedWidget: {
      widget: 'card',
      main: {
        widget: 'text',
        field: 'screen_name',
        variant: 'h5'
      },
      image: {
        widget: 'logo',
        field: 'logo_url'
      }
    }
  },
  pagination: {
    mappers: {
      count: 'meta.total',
      perPage: 'meta.per_page'
    }
  },
  columns: [
    {
      label: 'Name',
      sortable: true,
      widget: 'card',
      main: {
        widget: 'text',
        field: 'screen_name',
        variant: 'h5'
      },
      image: {
        widget: 'logo',
        field: 'logo_url'
      },
      secondary: {
        field: 'activeSubscription.price.product.name'
      }
    },
    {
      label: 'Created',
      field: 'created_at',
      widget: 'datetime',
      sortable: true
    }
  ]
};
