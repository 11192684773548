import { productTable } from '../product/shared';

export default {
  title: 'Supplier Detail',
  actions: [
    {
      label: 'Edit',
      type: 'edit'
    },
    {
      label: 'Delete',
      type: 'delete'
    }
  ],
  elements: [
    {
      type: 'detailsPanel',
      config: {
        actions: {
          fetch: {
            endpoint: 'suppliers/{id}',
            method: 'GET',
            dataPath: 'data'
          }
        },
        sections: [
          {
            headerWidget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'screen_name',
                variant: 'h4'
              },
              image: {
                widget: 'logo',
                field: 'logo_url',
                width: 80,
                height: 80
              }
            },
            fields: [
              {
                label: 'Address',
                field: 'address'
              },
              {
                label: 'Locality',
                field: 'company_locality'
              },
              {
                label: 'C.A.P.',
                field: 'zipcode'
              },
              {
                label: 'Phone',
                field: 'phone'
              }
            ]
          },
          {
            fields: [
              {
                label: 'Description',
                field: 'company_description',
                widget: 'htmlContent',
                lines: 10
              }
            ]
          }
        ]
      }
    },
    {
      title: 'Supplier products',
      ...productTable,
      config: {
        ...productTable.config,
        defaultParams: {
          'filter[supplier_id]': '{resourceId}'
        },
        maxHeight: 550,
        filters: {
          fields: [
            {
              label: 'Search',
              name: 'filter[name]',
              size: 'small',
              span: 8
            }
          ]
        }
      }
    },
    {
      title: 'Order requests',
      type: 'dataTable',
      config: {
        url: 'order-requests',
        defaultParams: {
          'filter[supplier_id]': '{resourceId}'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'From Buyer',
            field: 'buyer.display_name'
          }
        ]
      }
    }
  ]
};
