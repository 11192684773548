import React from 'react';
import { Box, CardHeader, Divider, CardContent, Card } from '@material-ui/core';

import Fieldset from 'src/components/forms/Fieldset';
import { makeStyles } from '@material-ui/styles';

const useCardStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
}));

const useCardHeaderStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ebedef'
  }
}));

const CardSection = ({ section, secondary, initialValues }) => {
  const cardClasses = useCardStyles();
  const cardHeaderClasses = useCardHeaderStyles();
  return (
    <Card classes={{ root: secondary ? cardClasses.root : null }}>
      {section.title && (
        <CardHeader
          title={section.title}
          classes={{ root: !secondary ? cardHeaderClasses.root : null }}
        />
      )}
      <Divider light={secondary} variant={secondary ? 'middle' : undefined} />
      <CardContent>
        <Fieldset fields={section.fields} parentRecord={initialValues} />
      </CardContent>
    </Card>
  );
};

const PlainSection = ({ section, initialValues }) => {
  return <Fieldset fields={section.fields} parentRecord={initialValues} />;
};

const Fieldsets = ({
  sections,
  initialValues,
  secondary = false,
  variant = 'card'
}) => {
  const fieldsets = sections.map((section, sectionIndex) => {
    const SectionCmp = variant === 'card' ? CardSection : PlainSection;

    return (
      <Box mb={secondary ? 0 : 2} key={sectionIndex}>
        <SectionCmp
          section={section}
          secondary={secondary}
          initialValues={initialValues}
        />
      </Box>
    );
  });

  return <React.Fragment>{fieldsets}</React.Fragment>;
};

export default Fieldsets;
