import template from 'src/data/types/template';
import { supplierListCard } from '../supplier/shared';

export default {
  title: 'Elenco Checkout Membership',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'membership-checkouts',
        response: {
          mappers: {
            list: 'data'
          }
        },
        defaultParams: {
          sort: '-created_at'
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Supplier',
            widget: {
              ...supplierListCard
            }
          },
          {
            label: 'Plan',
            widget: {
              widget: 'card',
              main: 'price.product.name',
              secondary: {
                field: 'price.amount',
                widget: 'monetaryAmount',
                currency: template('EUR'),
                size: 'small'
              }
            }
          },
          {
            label: 'Completed',
            field: 'is_completed',
            widget: 'check'
          },

          {
            label: 'URL',
            field: 'checkout_url',
            widget: 'url',
            truncate: 10
          }
        ]
      }
    }
  ]
};
