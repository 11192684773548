import * as yup from 'yup';

export const main = {
  title: 'Main info',
  initialValues: {},
  schema: yup.object().shape({}),

  sections: [
    {
      title: 'Main details',
      fields: [
        {
          label: 'Plan Name',
          name: 'name'
        },
        {
          label: 'Plan Description',
          name: 'description',
          multiline: true
        },
        {
          label: 'Active',
          name: 'active',
          type: 'switch'
        }
      ]
    }
  ]
};
