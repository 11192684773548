export default {
  title: 'Category list',
  elements: [
    {
      type: 'treeTable',
      config: {
        url: 'categories',
        defaultParams: {
          mode: 'tree'
        },
        response: {
          dataPath: 'data'
        },
        rowActions: [
          {
            type: 'move',
            direction: 'up',
            request: {
              method: 'POST',
              url: '/categories/{id}/move/up'
            }
          },
          {
            type: 'move',
            direction: 'down',
            request: {
              method: 'POST',
              url: '/categories/{id}/move/down'
            }
          },
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ],
        columns: [
          {
            label: 'Name',
            span: 7,
            widget: 'card',
            main: 'name',
            image: {
              field: 'listing_image_url',
              width: 75,
              height: 75
            }
          },
          {
            label: 'Products',
            span: 1,
            field: 'products_count'
          },
          {
            label: 'Subcategories',
            span: 1,
            field: 'children_count'
          }
        ]
      }
    }
  ]
};
