import React, { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  InputLabel
} from '@material-ui/core';

import { get } from 'lodash';

import { useFormContext } from 'react-hook-form';

import DataTable from 'src/generics/elements/DataTable';
import Widget from 'src/generics/widgets/Widget';
import useData from 'src/hooks/useData';

export default React.forwardRef(function RelatedPickerField(
  {
    name,
    value,
    label,
    onChange,
    viewConfig,
    namePrefix,
    title,
    parentRecord = null,
    initialValuePath = null
  },
  ref
) {
  const { errors } = useFormContext();
  const { initialValues } = useData();
  const { selection } = viewConfig;
  const error = get(errors, name);

  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [hasSelectedNewValue, setHasSelectedNewValue] = useState(false);

  const onOpenSelection = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const onSelect = entry => {
    setSelected(entry);
  };

  const handleSelectConfirm = () => {
    onChange(selected.id);
    setHasSelectedNewValue(true);
    setModalOpen(false);
  };

  // console.log(name, initialValuePath, namePrefix);

  // const initialSelected =
  //   parentRecord && initialValuePath
  //     ? get(parentRecord, initialValuePath)
  //     : null;

  // content_blocks[1].items[1].$item.id
  const initialSelected = get(
    initialValues,
    `${namePrefix}.${initialValuePath}`
  );

  const currentlySelected = !hasSelectedNewValue ? initialSelected : selected;

  const selectedWidget = currentlySelected ? (
    <Widget attrs={selection.selectedWidget} data={currentlySelected} />
  ) : null;

  return (
    <FormControl fullWidth>
      <Box mb={1}>
        <InputLabel shrink>{label}</InputLabel>
      </Box>

      {error && <Typography color="error">{error.message}</Typography>}

      <Card onClick={onOpenSelection}>
        <CardActionArea>
          <CardContent>
            {selectedWidget ? (
              selectedWidget
            ) : (
              <Typography variant="h5" color="textSecondary" component="h2">
                Seleziona...
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        onSubmit={e => e.stopPropagation()}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DataTable
            config={viewConfig}
            pickerMode
            onSelect={onSelect}
            selectedKey={selected ? selected.id : value}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annulla
          </Button>
          <Button
            onClick={handleSelectConfirm}
            color="primary"
            disabled={!selected}
          >
            Seleziona
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
});
