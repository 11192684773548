import React from 'react';
import { Eye, EyeOff } from 'react-feather';
import { get } from 'lodash';
import { SvgIcon } from '@material-ui/core';

const PublishedIcon = ({ data, field }) => {
  const value = get(data, field);
  const icon = value ? <Eye /> : <EyeOff />;

  return <SvgIcon fontSize="small">{icon}</SvgIcon>;
};

export default PublishedIcon;
