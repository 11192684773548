export default {
  title: 'Business list',
  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'businesses',
        defaultParams: {
          expand: 'address__province,logo'
        },
        response: {
          mappers: {
            list: 'results'
          }
        },
        pagination: {
          mappers: {
            count: 'count'
          }
        },
        columns: [
          {
            label: 'Name',
            span: 8,
            widget: 'card',
            main: 'name',
            image: {
              field: 'logo.file',
              width: 75,
              height: 75
            }
          },
          {
            label: 'Provincia',
            field: 'address.province.name'
          },
          {
            label: 'Registrato il',
            field: 'created',
            widget: 'datetime'
          }
        ]
      }
    }
  ]
};
