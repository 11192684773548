import React from 'react';
import { get } from 'lodash';
import QRCodeComponent from 'qrcode.react';

const QRCode = ({ data, field, size }) => {
  const value = get(data, field);

  if (!value) {
    return null;
  }

  return <QRCodeComponent value={value} size={size} />;
};

export default QRCode;
