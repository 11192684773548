import template from 'src/data/types/template';

export default {
  title: 'Elenco Prodotti Membership',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'membership-products',
        response: {
          mappers: {
            list: 'data'
          }
        },

        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Name',
            field: template('{{ item.name }}'),
            sortable: true
          },
          {
            label: 'Stripe identifier',
            field: 'external_id',
            widget: 'identifierLabel'
          },
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          }
        ]
      }
    }
  ]
};
