export default {
  title: 'Message List',

  elements: [
    {
      type: 'dataTable',
      config: {
        // filters: {
        //   fields: [
        //     {
        //       span: 4,
        //       label: 'Author',
        //       name: 'filter[from_user_id]',
        //       type: 'lazySelect',
        //       inlineLabel: true,
        //       displayAttr: 'display_name',
        //       selectedOptionAttr: 'from_user',
        //       request: {
        //         endpoint: 'users',
        //         params: { sort: 'display_name', nopagination: true },
        //         dataPath: 'data'
        //       },
        //       selectedWidget: {
        //         widget: 'card',
        //         main: 'display_name'
        //       },
        //       optionWidget: {
        //         widget: 'card',
        //         main: 'display_name'
        //       }
        //     }
        //   ]
        // },
        url: 'messages',
        defaultParams: {
          sort: '-created_at'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Sent at',
            widget: 'card',
            main: {
              widget: 'text',
              field: 'title'
            },
            secondary: {
              field: 'created_at',
              widget: 'datetime'
            }
          },
          {
            label: 'Author',
            widget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'from_user.display_name'
              }
            }
          }
        ]
      }
    }
  ]
};
