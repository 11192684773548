import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText
} from '@material-ui/core';

const ConfirmDeleteDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={true}>
      <DialogTitle>Elimina record</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
