export default {
  title: 'Elenco Clienti',
  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'customers',
        defaultParams: {
          expand: 'home_address__province,user'
        },
        response: {
          mappers: {
            list: 'results'
          }
        },
        pagination: {
          mappers: {
            count: 'count'
          }
        },
        columns: [
          {
            label: 'Nome',
            field: 'firstName'
          },
          {
            label: 'Cognome',
            field: 'lastName'
          },
          {
            label: 'Email',
            field: 'user.email'
          },
          {
            label: 'Provincia',
            field: 'homeAddress.province.name'
          },
          {
            label: 'Registrato il',
            field: 'created',
            widget: 'datetime'
          }
        ]
      }
    }
  ]
};
