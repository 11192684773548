import * as fieldset from './fieldset';
import configs from '../../configs';

export default {
  title: 'Edit Page',
  elements: [
    {
      title: 'Main',
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'pages/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'pages/{id}',
            method: 'PUT'
          }
        },
        fieldsets: [fieldset.main],
        versionFormView: {
          maxWidth: 'lg',
          versions: configs.versions,
          type: 'editForm',
          config: {
            actions: {
              fetch: {
                endpoint: 'pages/{id}',
                method: 'GET',
                dataPath: 'data',
                params: {
                  lang: '{versionId}'
                }
              },
              save: {
                endpoint: 'pages/{id}',
                method: 'PUT',
                params: {
                  lang: '{versionId}'
                }
              }
            },
            fieldsets: [
              {
                sections: [
                  {
                    title: 'Content blocks',
                    fields: [fieldset.contentBlocksField]
                  }
                ]
              }
            ]
          }
        }
      }
    }
  ]
};
