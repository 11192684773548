export const main = {
  title: 'Main info',
  initialValues: {
    screen_name: ''
  },
  sections: [
    {
      title: 'Main details',
      fields: [
        {
          label: 'Name',
          name: 'screen_name'
        },
        {
          label: 'Company Description',
          name: 'company_description',
          type: 'richText'
        },
        {
          label: 'Phone',
          name: 'phone'
        },
        {
          label: 'PEC',
          name: 'pec'
        }
      ]
    },
    {
      title: 'Business Address',
      fields: [
        {
          label: 'Address',
          name: 'address'
        },
        {
          label: 'Locality',
          name: 'company_locality'
        },
        {
          label: 'Postal code',
          name: 'zipcode'
        }
      ]
    }
  ]
};

export const account = {
  title: 'Account',
  initialValues: {
    generate_password: 'yes',
    password: 'whatever'
  },
  sections: [
    {
      title: 'Reset password',
      conditions: {
        manual_password_selected: {
          generate_password: 'no'
        }
      },
      fields: [
        {
          name: 'generate_password',
          type: 'radioGroup',
          row: true,
          options: [
            { label: 'Generate new password automatically', value: 'yes' },
            { label: 'Set new password manually', value: 'no' }
          ]
        },
        {
          showOnlyIf: 'manual_password_selected',
          label: 'New password',
          name: 'password',
          InputProps: {
            type: 'password'
          }
        },
        {
          label: 'Confirm password',
          name: 'password_confirm',
          InputProps: {
            type: 'password'
          }
        }
      ]
    }
  ]
};
