import heroBgField from '../fields/heroBgField';
import ctaField from '../fields/ctaField';

export default {
  value: 'main_hero',
  label: 'Main Hero',
  fields: [
    {
      label: 'Title',
      name: 'title'
    },
    {
      label: 'Content',
      name: 'content',
      type: 'richText'
    },
    heroBgField,
    ctaField
  ]
};
