import blockTypes from '../content/blockTypes';

export const main = {
  title: 'Main info',
  initialValues: {
    published: false,
    content: []
  },
  sections: [
    {
      fields: [
        {
          name: 'title',
          label: 'Title'
        },
        {
          name: 'published',
          label: 'Published',
          type: 'switch'
        },
        {
          name: 'excerpt',
          label: 'Excerpt',
          multiline: true
        }
      ]
    },
    {
      title: 'Content',
      fields: [
        {
          name: 'content',
          type: 'hasMany',
          includeIds: false,
          collapsible: true,
          layout: 'verticalList',
          typeSelector: {
            typeFieldName: 'block_type',
            addLabel: 'Add new block:',
            options: [
              blockTypes.two_cols_panel,
              blockTypes.two_cols_text,
              blockTypes.hero,
              blockTypes.hero_with_actions,
              blockTypes.hero_with_box,
              blockTypes.hero_with_spaces,
              blockTypes.asymmetric_header,
              blockTypes.info_box,
              blockTypes.product_carousel,
              blockTypes.broken_cols,
              blockTypes.hero_carousel,
              blockTypes.text_carousel,
              blockTypes.image_full,
              blockTypes.text_wall
            ]
          }
        }
      ]
    }
  ]
};
