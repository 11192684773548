import * as yup from 'yup';

export const createFieldset = {
  title: 'Dettagli',
  initialValues: {
    name: '',
    codename: ''
  },
  schema: yup.object().shape({
    name: yup.string().required(),
    codename: yup.string().required()
  }),
  sections: [
    {
      title: 'Dati principali',
      fields: [
        {
          label: 'Nome',
          name: 'name'
        },
        {
          label: 'Identificativo interno',
          name: 'codename'
        }
      ]
    }
  ]
};
