import React, { useRef } from 'react';
import { Box, Button, Grid } from '@material-ui/core';

import Form from 'src/components/forms/Form';
import FormField from 'src/components/forms/FormField';

const Filters = ({
  fields = [],
  initialValues = {},
  onApplyFilters,
  onResetFilters
}) => {
  const formRef = useRef();

  const onSave = e => {
    e.preventDefault();
    formRef.current.handleSubmit(
      async data => {
        onApplyFilters(data);
      },
      errors => {
        console.log('Form validation errors', errors);
      }
    )();
  };

  const renderedFields = (
    <Grid container spacing={3} alignItems="center">
      {fields.map((field, index) => {
        return (
          <FormField
            key={index}
            {...field}
            Wrapper={{ Cmp: Grid, props: { item: true, md: field.span } }}
          />
        );
      })}
    </Grid>
  );

  const handleResetFilters = () => {
    onResetFilters();
    formRef.current.reset(initialValues);
  };

  const filterCard = (
    <Box mb={2}>
      <Box display="flex">
        <Box display="flex" flex={1}>
          {renderedFields}
        </Box>
        <Box ml={2} display="flex" alignItems="center">
          <Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              Apply
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              type="button"
              onClick={handleResetFilters}
              variant="outlined"
              color="primary"
              size="small"
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box my={2}>
      <form onSubmit={onSave}>
        <Form
          ref={formRef}
          initialValues={initialValues}
          fieldset={filterCard}
        />
      </form>
    </Box>
  );
};

export default Filters;
