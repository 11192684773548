import heroBgField from '../fields/heroBgField';
import ctaField from '../fields/ctaField';

export default {
  value: 'hero_with_actions',
  label: 'Hero with actions',
  fields: [
    {
      label: 'Title',
      name: 'title'
    },
    {
      label: 'Subtitle',
      name: 'subtitle'
    },
    heroBgField,
    ctaField
  ]
};
