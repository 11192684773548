import React from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';

import { AuthProvider } from 'src/contexts/JWTAuthContext';

import { createTheme } from 'src/theme';
import routes, { AppRoutes } from 'src/routes';
import ApiClientContext from 'src/contexts/ApiClientContext';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  return (
    <Router history={history}>
      <Route
        path="/:appName"
        render={({ match }) => {
          const theme = createTheme(match.params.appName);

          return (
            <ThemeProvider theme={theme}>
              <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <SnackbarProvider dense maxSnack={3}>
                    <ApiClientContext>
                      <AuthProvider>
                        <GlobalStyles />
                        <ScrollReset />

                        <AppRoutes routes={routes} />
                      </AuthProvider>
                    </ApiClientContext>
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </StylesProvider>
            </ThemeProvider>
          );
        }}
      ></Route>
    </Router>
  );
};

export default App;
