import { productTable } from '../product/shared';
import { supplierListCard } from '../supplier/shared';

export default {
  title: 'Product Detail',
  actions: [
    {
      label: 'Edit',
      type: 'edit'
    },
    {
      label: 'Delete',
      type: 'delete'
    }
  ],
  elements: [
    {
      type: 'detailsPanel',
      config: {
        actions: {
          fetch: {
            endpoint: 'products/{id}',
            method: 'GET',
            dataPath: 'data'
          }
        },
        sections: [
          {
            headerWidget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'name',
                variant: 'h4'
              },
              image: {
                widget: 'thumb',
                field: 'listing_image_url',
                width: 80,
                height: 80
              }
            },
            fields: [
              {
                label: 'Supplier',
                ...supplierListCard
              },
              {
                label: 'SKU',
                field: 'sku',
                widget: 'identifierLabel'
              },
              {
                label: 'Published',
                field: 'published',
                widget: 'publishedIcon'
              }
            ]
          },
          {
            fields: [
              {
                label: 'Description',
                field: 'description',
                widget: 'htmlContent',
                lines: 10
              }
            ]
          }
        ]
      }
    },
    {
      title: 'Order requests containing this product',
      type: 'dataTable',
      config: {
        url: 'order-requests',
        defaultParams: {
          'filter[product_id]': '{resourceId}'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'From Buyer',
            field: 'buyer.display_name'
          }
        ]
      }
    }
  ]
};
