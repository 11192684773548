import React from 'react';

import { TreeItem, TreeView } from '@material-ui/lab';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider
} from '@material-ui/core';

import useTreeView from 'src/hooks/useTreeView';
import ConfirmDeleteDialog from 'src/components/ConfirmDeleteDialog';
import ResultActions from 'src/generics/elements/ResultActions';

import TableLoader from 'src/components/TableLoader';
import { makeStyles } from '@material-ui/styles';

import Widget from 'src/generics/widgets/Widget';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  label: {
    borderBottom: '1px solid #eee',
    padding: 4,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  group: {
    marginLeft: 0
  },
  selected: {
    '& .MuiTreeItem-label': {
      backgroundColor: 'white'
    }
  }
}));

const Item = ({ node, columns, depth = 0, rowActions, context }) => {
  return (
    <Grid container display="flex" alignItems="center" spacing={2}>
      {columns.map((col, index) => {
        let content = <Widget data={node} attrs={col} />;

        if (!index) {
          content = (
            <Box
              style={{ paddingLeft: depth * 20 }}
              display="flex"
              alignItems="center"
            >
              {content}
            </Box>
          );
        }

        return (
          <Grid sm={col.span} item key={index}>
            {content}
          </Grid>
        );
      })}

      <Grid sm={3} item>
        <ResultActions
          rowActions={rowActions}
          result={node}
          context={context}
        />
      </Grid>
    </Grid>
  );
};

const Header = ({ columns }) => {
  return (
    <Grid container display="flex" alignItems="center" spacing={2}>
      {columns.map((col, index) => (
        <Grid sm={col.span} item key={index}>
          <Typography variant="subtitle2">{col.label}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const NodeList = ({ nodes, columns, depth = 0, rowActions, context }) => {
  const classes = useStyles();

  if (!nodes) {
    console.log('node chilrend mepty', nodes);
  }

  return (
    <React.Fragment>
      {nodes.map(node => {
        const childItems = node.children.length ? (
          <NodeList
            nodes={node.children}
            depth={depth + 1}
            columns={columns}
            context={context}
            rowActions={rowActions}
          />
        ) : null;

        const key = node.id;

        const label = (
          <Item
            node={node}
            depth={depth}
            columns={columns}
            context={context}
            rowActions={rowActions}
          />
        );

        return (
          <TreeItem key={key} nodeId={`${key}`} label={label} classes={classes}>
            {childItems}
          </TreeItem>
        );
      })}
    </React.Fragment>
  );
};

const TreeTable = ({ config, resourceSlug }) => {
  const {
    actions,
    resultToDelete,
    onDelete,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleRowAction
  } = useTreeView({
    config
  });

  const { enqueueSnackbar } = useSnackbar();

  const onDeleteConfirm = async (...args) => {
    await handleDeleteConfirm(...args);
    enqueueSnackbar('The record has been deleted', {
      variant: 'warning'
    });
  };

  const { columns, rowActions } = config;

  const mainContent = actions.fetch.loading ? (
    <TableLoader />
  ) : (
    <Box p={4}>
      <Box mb={2}>
        <Header columns={columns} />
        <Divider />
      </Box>

      <TreeView
        defaultExpanded={[]}
        disableSelection
        selected={[]}
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
      >
        <NodeList
          nodes={actions.fetch.results}
          columns={columns}
          rowActions={rowActions}
          context={{
            onDelete,
            handleRowAction,
            resourceSlug
          }}
        />
      </TreeView>
    </Box>
  );

  return (
    <React.Fragment>
      <Card>
        <CardContent>{mainContent}</CardContent>
      </Card>
      {resultToDelete && (
        <ConfirmDeleteDialog
          onConfirm={onDeleteConfirm}
          onCancel={handleDeleteCancel}
          message={`Are you sure you want to delete this record?`}
        />
      )}
    </React.Fragment>
  );
};

export default TreeTable;
