import React from 'react';
import { getValue } from 'src/dataUtils';
import { Link } from '@material-ui/core';
import truncateMiddle from 'truncate-middle';

const Url = ({ data, field, truncate }) => {
  const value = getValue(data, field);

  const formattedVal = truncate
    ? truncateMiddle(value, truncate, truncate, '...')
    : value;

  return (
    <Link href={value} target="blank">
      {formattedVal}
    </Link>
  );
};

export default Url;
