import blockBgColorField from '../fields/blockBgColorField';
import ctaField from '../fields/ctaField';
import blockImageField from '../fields/blockImageField';

export default {
  value: 'two_cols_panel',
  label: 'Two cols panel',
  fields: {
    left: [
      blockBgColorField,
      {
        label: 'Title',
        name: 'title'
      },
      {
        label: 'Content',
        name: 'content',
        type: 'richText'
      },
      ctaField
    ],
    right: [
      {
        label: 'Image position',
        name: 'image_position',
        type: 'radioGroup',
        row: true,
        options: [
          { label: 'Left', value: 'order-first' },
          { label: 'Right', value: 'order-last' },
        ]
      },
      blockImageField
    ]

  }
};
