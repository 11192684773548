import React, { useState } from 'react';

import { get } from 'lodash';
import { TreeItem, TreeView } from '@material-ui/lab';
import { ExpandMore, ChevronRight } from '@material-ui/icons';

import useAction from 'src/hooks/useAction';
import {
  Checkbox,
  Box,
  InputLabel,
  FormControl,
  Select,
  Button,
  Divider
} from '@material-ui/core';
import TableLoader from '../../TableLoader';
import useCurrentResource from 'src/hooks/useCurrentResource';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    background: 'white'
  }
});

const buildTree = (nodes, selectedOption, onItemChecked, valueToDisable) => {
  return (
    <React.Fragment>
      {nodes.map(node => {
        const childItems = node.children.length
          ? buildTree(
              node.children,
              selectedOption,
              onItemChecked,
              valueToDisable
            )
          : null;

        const optionValue = node.id;

        const disabled = `${optionValue}` === `${valueToDisable}`;

        const checked = selectedOption === optionValue;

        const handleChange = event => {
          if (disabled) {
            return;
          }
          onItemChecked(node, event.target.checked);
        };

        const label = (
          <React.Fragment>
            <Checkbox
              onClick={e => e.stopPropagation()}
              checked={checked}
              onChange={handleChange}
              indeterminate={disabled}
            />

            {node.name}
          </React.Fragment>
        );

        return (
          <TreeItem key={optionValue} nodeId={`${optionValue}`} label={label}>
            {childItems}
          </TreeItem>
        );
      })}
    </React.Fragment>
  );
};

export default React.forwardRef(function TreeSelect(
  {
    value,
    onChange,
    actionParams,
    label,
    clearable = true,
    clearLabel = 'Clear',
    size,
    emptyValueLabel = 'All',
    selectedOptionAttr,
    labelAttr,
    parentRecord,
    response
  },
  ref
) {
  const classes = useStyles();
  const [newSelectedItem, setNewSelectedItem] = useState(null);
  const fetchOptions = useAction(actionParams);

  const currentResource = useCurrentResource();

  let initialOptionValue = null;

  if (selectedOptionAttr && parentRecord) {
    initialOptionValue = get(parentRecord, selectedOptionAttr);
  }

  const getDisplayLabel = item => (labelAttr ? get(item, labelAttr) : '-');

  const onItemChecked = node => {
    setNewSelectedItem(node);
    onChange(node.id);
  };

  const onOpen = () => {
    fetchOptions.execute();
  };

  const onClear = () => {
    setNewSelectedItem(null);
    onChange(null);
  };

  const dataPath = get(response, 'dataPath');

  const fetchedOptions = fetchOptions.data
    ? dataPath
      ? get(fetchOptions.data, dataPath)
      : fetchOptions.data
    : fetchOptions.data;

  const mainContent = !fetchOptions.data ? (
    <TableLoader width={150} />
  ) : (
    <Box>
      {clearable && (
        <Box pb={1}>
          <Button size="small" onClick={onClear} variant="outlined">
            {clearLabel}
          </Button>
        </Box>
      )}
      <Divider />
      <Box>
        <TreeView
          defaultExpanded={[]}
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          onNodeToggle={e => e.stopPropagation()}
        >
          {buildTree(fetchedOptions, value, onItemChecked, currentResource.id)}
        </TreeView>
      </Box>
    </Box>
  );

  return (
    <FormControl variant="outlined" fullWidth size={size}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        classes={{
          root: classes.root
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
        onOpen={onOpen}
        label={label}
        displayEmpty
        renderValue={() => {
          return newSelectedItem
            ? getDisplayLabel(newSelectedItem)
            : initialOptionValue
            ? getDisplayLabel(initialOptionValue)
            : emptyValueLabel;
        }}
      >
        <Box
          p={1}
          pt={0}
          onClick={e => e.stopPropagation()}
          maxHeight={200}
          overflow="auto"
        >
          {mainContent}
        </Box>
      </Select>
    </FormControl>
  );
});
