export default {
  title: 'Order Request List',

  elements: [
    {
      type: 'dataTable',
      config: {
        filters: {
          fields: [
            {
              span: 4,
              label: 'To supplier',
              name: 'filter[supplier_id]',
              type: 'lazySelect',
              inlineLabel: true,
              displayAttr: 'screen_name',
              selectedOptionAttr: 'supplier',
              request: {
                endpoint: 'suppliers',
                params: { sort: 'screen_name', nopagination: true },
                dataPath: 'data'
              },
              selectedWidget: {
                widget: 'card',
                main: 'screen_name',
                image: {
                  field: 'logo_url',
                  width: 20,
                  height: 20
                }
              },
              optionWidget: {
                widget: 'card',
                main: 'screen_name',
                image: {
                  field: 'logo_url',
                  width: 20,
                  height: 20
                }
              }
            },
            {
              span: 4,
              label: 'Search title',
              name: 'filter[title]'
            }
          ]
        },
        url: 'order-requests',
        defaultParams: {
          sort: '-created_at'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        tabs: [
          {
            id: 'all',
            label: 'All',
            requestParams: {}
          },
          {
            id: 'draft',
            label: 'Drafts',
            requestParams: {
              'filter[draft]': 1
            }
          },
          {
            id: 'sent',
            label: 'Sent',
            requestParams: {
              'filter[draft]': 0
            }
          }
        ],
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            widget: 'card',
            main: {
              widget: 'text',
              field: 'title'
            },
            secondary: {
              field: 'created_at',
              widget: 'datetime'
            }
          },
          {
            label: 'To Supplier',
            widget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'supplier.screen_name'
              },
              image: {
                widget: 'logo',
                field: 'supplier.logo_url',
                width: 40,
                height: 40
              }
            }
          },
          {
            label: 'From Buyer',
            field: 'buyer.display_name'
          },
          {
            label: 'Sent at',
            field: 'sent_at',
            widget: 'datetime'
          }
        ]
      }
    }
  ]
};
