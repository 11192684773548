import React from 'react';

import { Grid } from '@material-ui/core';

import FormField from 'src/components/forms/FormField';

const Fieldset = ({ fields, parentRecord }) => {
  const fieldComponents = (
    <Grid container spacing={3}>
      {fields.map((field, index) => {
        const span = field.span || 12;

        const renderedField = (
          <FormField
            key={index}
            parentRecord={parentRecord}
            {...field}
            Wrapper={{ Cmp: Grid, props: { item: true, md: span } }}
          />
        );

        if (field.type === 'hidden') {
          return renderedField;
        }

        return renderedField;
      })}
    </Grid>
  );

  return fieldComponents;
};

export default Fieldset;
