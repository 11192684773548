export default {
  label: 'Call to action',
  name: 'cta',
  type: 'hasOne',
  includeId: false,
  fields: [
    {
      label: 'Button text',
      name: 'label'
    },
    {
      label: 'URL',
      name: 'url'
    }
  ]
};
