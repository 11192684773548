import React, { useRef } from 'react';
import { get, isPlainObject } from 'lodash';
import { Link as RouterLink, useParams } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableSortLabel,
  TableContainer
} from '@material-ui/core';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import * as yup from 'yup';

import {
  ArrowRight as ArrowRightIcon,
  Filter as FilterIcon
} from 'react-feather';

import TableLoader from 'src/components/TableLoader';
import submitForms from 'src/utils/forms/submitForms';
import Form from 'src/components/forms/Form';
import FormField from 'src/components/forms/FormField';
import Widget from 'src/generics/widgets/Widget';
import useDataTable from './useDataTable';
import ResultActions from 'src/generics/elements/ResultActions';

const ColumnFilterForm = ({ column, appliedFilters, onApplyFilters }) => {
  const formRef = useRef();

  const paramName = column.filter.param;

  const fieldset = <FormField {...column.filter} name={paramName} />;

  const filterSchema = {
    schema: yup.object().shape({
      [paramName]: yup.string()
    }),
    initialValues: {
      [paramName]: get(appliedFilters, paramName, '')
    }
  };

  const onSave = async event => {
    event.preventDefault();

    const results = await submitForms([formRef.current]);

    if (!results.allValid) {
      return;
    }

    const [filterValues] = results.data;

    onApplyFilters(filterValues);
  };

  return (
    <form onSubmit={onSave}>
      <Form
        ref={formRef}
        schema={filterSchema.schema}
        initialValues={filterSchema.initialValues}
        fieldset={fieldset}
      />
      <Box mt={1}>
        <Button type="submit" variant="contained" color="primary" size="small">
          Apply
        </Button>
      </Box>
    </form>
  );
};

const FilterPopup = ({ column, onApplyFilters, appliedFilters }) => {
  return (
    <Box ml={2}>
      <PopupState variant="popover">
        {popupState => (
          <div>
            <IconButton {...bindTrigger(popupState)}>
              <SvgIcon fontSize="small" color="disabled">
                <FilterIcon />
              </SvgIcon>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box p={2}>
                <ColumnFilterForm
                  column={column}
                  onApplyFilters={onApplyFilters}
                  appliedFilters={appliedFilters}
                />
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Box>
  );
};

const Results = () => {
  const { appName } = useParams();

  const {
    config,
    columns,
    results,
    loading,
    resourceSlug,
    onApplyFilters,
    appliedFilters,
    sortBy,
    sortByDir,
    onApplySortBy,
    pickerMode = false,
    onSelect = null,
    selectedKey = null,
    maxHeight,
    handleToggleRowSelection,
    handleToggleSelectAll,
    selectedRows = [],
    handleRowAction,
    onDelete
  } = useDataTable();

  return (
    <PerfectScrollbar>
      <Box minWidth={700}>
        <TableContainer style={{ maxHeight }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < results.length
                    }
                    onChange={event =>
                      handleToggleSelectAll &&
                      handleToggleSelectAll(event.target.checked)
                    }
                  />
                </TableCell>

                {columns.map((column, colIndex) => {
                  return (
                    <TableCell
                      key={colIndex}
                      align={column.align}
                      sortDirection={'asc'}
                    >
                      <Box display="flex" alignItems="center">
                        {column.sortable ? (
                          <TableSortLabel
                            active={sortBy === column.field}
                            direction={
                              sortBy === column.field ? sortByDir : 'asc'
                            }
                            onClick={() => {
                              onApplySortBy(
                                column.field,
                                sortByDir === 'asc' ? 'desc' : 'asc'
                              );
                            }}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          column.label
                        )}

                        {column.filter && (
                          <FilterPopup
                            column={column}
                            onApplyFilters={onApplyFilters}
                            appliedFilters={appliedFilters}
                          />
                        )}
                      </Box>
                    </TableCell>
                  );
                })}

                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan={columns.length + 2}>
                    <TableLoader />
                  </TableCell>
                </TableRow>
              )}

              {!loading && !results.length && (
                <TableRow>
                  <TableCell colSpan={columns.length + 2}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      py={12}
                    >
                      No results.
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {!loading &&
                results.map(result => {
                  const resultKey = result.id;
                  const isRowSelected = pickerMode
                    ? selectedKey === resultKey
                    : selectedRows.includes(resultKey);

                  return (
                    <TableRow
                      hover
                      key={result.id}
                      onClick={() => pickerMode && onSelect(result)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isRowSelected}
                          onChange={() =>
                            handleToggleRowSelection &&
                            handleToggleRowSelection(resultKey)
                          }
                        />
                      </TableCell>

                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex} align={column.align}>
                          <Widget
                            data={result}
                            attrs={
                              isPlainObject(column.widget)
                                ? column.widget
                                : column
                            }
                          />
                        </TableCell>
                      ))}

                      <TableCell align="right">
                        {!pickerMode && (
                          <ResultActions
                            result={result}
                            context={{
                              handleRowAction,
                              onDelete,
                              resourceSlug
                            }}
                          />
                        )}

                        {config.enableViewAction && (
                          <IconButton
                            component={RouterLink}
                            to={`/${appName}/app/${resourceSlug}/detail/${result.id}`}
                          >
                            <SvgIcon fontSize="small">
                              <ArrowRightIcon />
                            </SvgIcon>
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </PerfectScrollbar>
  );
};

export default Results;
