import heroBgField from '../fields/heroBgField';
import ctaField from '../fields/ctaField';

export default {
  value: 'hero_text_center',
  label: 'Hero with centered text',
  fields: [
    {
      label: 'Pretitle',
      name: 'pretitle'
    },
    {
      label: 'Title',
      name: 'title'
    },
    heroBgField,
    ctaField
  ]
};
