import * as yup from 'yup';

const mainFields = [
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Logo',
    name: 'logoId',
    type: 'image',
    initialUrlAttr: 'logo.file',
    request: {
      endpoint: 'images',
      method: 'POST'
    },
    response: {
      previewUrlPath: 'file',
      valuePath: 'id'
    }
  },
  {
    label: 'Descrizione',
    name: 'description',
    multiline: true
  },
  {
    label: 'Numero di telefono',
    name: 'phone_number',
  }

  // {
  //   label: 'Stats',
  //   name: 'bowlStats',
  //   type: 'hasMany',
  //   includeIds: true,
  //   layout: 'verticalList',
  //   fields: [
  //     {
  //       label: 'Tipologia Bowl',
  //       name: 'bowlTypeId',
  //       type: 'lazySelect',
  //       displayAttr: 'name',
  //       valueAttr: 'id',
  //       selectedOptionAttr: 'bowlType',
  //       request: {
  //         endpoint: 'bowl-types'
  //       }
  //     },
  //     {
  //       label: 'In count',
  //       name: 'inCount',
  //       type: 'number',
  //       span: 6
  //     },
  //     {
  //       label: 'Out count',
  //       name: 'outCount',
  //       type: 'number',
  //       span: 6
  //     }
  //   ]
  // }
];

const addressFields = [
  {
    label: 'Google place',
    type: 'googlePlace',
    name: 'place',
    mapFields: {
      point: 'point',
      streetNumber: 'streetNumber',
      streetName: 'streetName',
      city: 'city',
      region: 'region',
      postcode: 'postcode',
      province: 'province',
      longitude: 'location.coordinates[0]',
      latitude: 'location.coordinates[1]'
    }
  },
  {
    name: 'location.type',
    type: 'hidden',
    defaultValue: 'Point'
  },
  {
    label: 'Longitude',
    name: 'location.coordinates[0]'
  },
  {
    label: 'Latitude',
    name: 'location.coordinates[1]'
  },
  {
    label: 'Via / Corso / Piazza',
    name: 'streetName'
  },
  {
    label: 'Numero',
    name: 'streetNumber'
  },
  {
    label: 'C.A.P.',
    name: 'postcode'
  },
  {
    label: 'Comune',
    name: 'city'
  },
  {
    label: 'Provincia',
    name: 'provinceId',
    type: 'lazySelect',
    displayAttr: 'name',
    valueAttr: 'id',
    selectedOptionAttr: 'province',
    request: {
      endpoint: 'provinces'
    }
  }
];

const accountFields = [
  {
    label: 'Email',
    name: 'user.email'
  },
  {
    label: 'Password',
    name: 'user.password',
    type: 'password'
  }
];

export const addressFieldset = {
  title: 'Address',
  initialValues: {
    name: ''
  },
  schema: yup.object().shape({
    streetName: yup.string(),
    streetNumber: yup.string(),
    postcode: yup.string(),
    city: yup.string(),
    province: yup.string()
  }),
  sections: [
    {
      title: 'Address',
      fields: addressFields
    }
  ]
};

export const createFieldset = {
  title: 'Main info',
  initialValues: {
    name: ''
  },
  schema: yup.object().shape({
    name: yup.string()
  }),
  sections: [
    {
      title: 'Main details',
      fields: mainFields
    },
    {
      title: 'Address',
      fields: [
        {
          label: 'Google place',
          type: 'googlePlace',
          name: 'place',
          mapFields: {
            point: 'point',
            streetNumber: 'address.streetNumber',
            streetName: 'address.streetName',
            city: 'address.city',
            region: 'address.region',
            postcode: 'address.postcode',
            province: 'address.province',
            longitude: 'address.location.coordinates[0]',
            latitude: 'address.location.coordinates[1]'
          }
        },
        {
          name: 'address.location.type',
          type: 'hidden',
          defaultValue: 'Point'
        },
        {
          label: 'Longitude',
          name: 'address.location.coordinates[0]'
        },
        {
          label: 'Latitude',
          name: 'address.location.coordinates[1]'
        },
        {
          label: 'Via / Corso / Piazza',
          name: 'address.streetName'
        },
        {
          label: 'Numero',
          name: 'address.streetNumber'
        },
        {
          label: 'C.A.P.',
          name: 'address.postcode'
        },
        {
          label: 'Comune',
          name: 'address.city'
        },
        {
          label: 'Provincia',
          name: 'address.provinceId',
          type: 'lazySelect',
          displayAttr: 'name',
          valueAttr: 'id',
          selectedOptionAttr: 'province',
          request: {
            endpoint: 'provinces'
          }
        }
      ]
    },
    {
      title: 'Account',
      fields: accountFields
    }
  ]
};

export const editMainFieldset = {
  title: 'Main info',
  initialValues: null,
  schema: yup.object().shape({
    name: yup.string()
  }),
  sections: [
    {
      title: 'Main details',
      fields: mainFields
    }
  ]
};

export const editStatsFieldset = {
  initialValues: null,

  sections: [
    {
      title: 'Conteggi Bowl per tipologia',
      fields: [
        {
          label: 'Stats',
          name: 'bowlStats',
          type: 'hasMany',
          includeIds: true,
          layout: 'verticalList',
          fields: [
            {
              label: 'Tipologia Bowl',
              name: 'bowlTypeId',
              type: 'lazySelect',
              displayAttr: 'name',
              valueAttr: 'id',
              selectedOptionAttr: 'bowlType',
              request: {
                endpoint: 'bowl-types'
              }
            },
            {
              label: 'In count',
              name: 'inCount',
              type: 'number',
              span: 6
            },
            {
              label: 'Out count',
              name: 'outCount',
              type: 'number',
              span: 6
            }
          ]
        }
      ]
    }
  ]
};

export const editAddressFieldset = {
  title: 'Address',
  contextDataPrefix: 'address',
  initialValues: null,
  schema: yup.object().shape({
    // name: yup.string()
  }),
  sections: [
    {
      title: 'Address',
      fields: addressFields
    }
  ]
};

export const editAccountFieldset = {
  title: 'Account',
  contextDataPrefix: 'user',
  initialValues: null,
  schema: yup.object().shape({
    // name: yup.string()
  }),
  sections: [
    {
      title: 'Account',
      fields: [
        {
          label: 'Email',
          name: 'email'
        }
      ]
    }
  ]
};
