// import { productTable } from '../product/shared';

export default {
  title: 'Dettaglio Prodotto',
  actions: [
    {
      label: 'Modifica',
      type: 'edit'
    },
    {
      label: 'Elimina',
      type: 'delete'
    }
  ],
  elements: [
    {
      type: 'detailsPanel',
      config: {
        actions: {
          fetch: {
            endpoint: 'products/{id}',
            method: 'GET',
            params: {
              expand: 'main_image'
            }
          }
        },
        sections: [
          {
            headerWidget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'name',
                variant: 'h4'
              },
              image: {
                widget: 'thumb',
                field: 'mainImage.file',
                width: 100,
                height: 100
              }
            },
            fields: [
              {
                label: 'Pubblicata',
                field: 'published'
              },
              {
                label: 'Prezzo',
                field: 'price'
              },
              {
                label: 'Descrizione',
                field: 'description'
              }
            ]
          }
        ]
      }
    }
    // {
    //   title: 'Supplier products',
    //   ...productTable,
    //   config: {
    //     ...productTable.config,
    //     defaultParams: {
    //       'filter[supplier_id]': '{resourceId}'
    //     },
    //     maxHeight: 550,
    //     filters: {
    //       fields: [
    //         {
    //           label: 'Search',
    //           name: 'filter[name]',
    //           size: 'small',
    //           span: 8
    //         }
    //       ]
    //     }
    //   }
    // }
  ]
};
