import * as yup from 'yup';

export const main = {
  title: 'Main info',
  initialValues: {},
  schema: yup.object().shape({}),

  sections: [
    {
      title: 'Main details',
      fields: [
        {
          label: 'Name',
          name: 'name'
        },
        {
          label: 'Stripe ID',
          name: 'external_id'
        },
        {
          label: 'Type',
          name: 'type',
          type: 'radioGroup',
          row: true,
          options: [
            { label: 'Percentage discount', value: 'percentage' },
            { label: 'Fixed amount discount', value: 'fixed_amount' }
          ]
        },
        {
          label: 'Amount off',
          name: 'amount_off',
          type: 'number'
        },
        {
          label: 'Percentage off',
          name: 'percentage_off',
          type: 'number'
        },
        {
          label: 'Description',
          name: 'description',
          multiline: true
        }
      ]
    }
  ]
};
