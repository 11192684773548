export default {
  title: 'Pages',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'pages',
        defaultParams: {},
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        disableViewAction: true,
        columns: [
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Codename',
            field: 'codename',
            widget: 'text',
            variant: 'caption'
          },
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Last modified',
            field: 'updated_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'Published',
            field: 'published',
            widget: 'publishedIcon'
          },
          {
            label: 'URL',
            field: 'url',
            widget: 'url',
            truncate: 10
          }
        ]
      }
    }
  ]
};
