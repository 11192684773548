import React, { useState, forwardRef } from 'react';
import { get } from 'lodash';

import useAction from 'src/hooks/useAction';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
  FormHelperText,
  TextField,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Widget from 'src/generics/widgets/Widget';
import TableLoader from 'src/components/TableLoader';

const useStyles = makeStyles(() => ({
  selectRoot: {
    background: 'white'
  },
  menu: {
    maxHeight: 300,
    overflow: 'auto'
  }
}));

export default forwardRef(function LazySelect(
  {
    valueAttr,
    displayAttr,
    selectedWidget,
    optionWidget,
    label,
    size,
    helperText,
    parentRecord = null,
    request,
    selectedOptionAttr = null,
    error,
    searchable = false,
    searchableFieldPath = null,
    ...rest
  },
  ref
) {
  const [newSelectedItem, setNewSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [cleared, setCleared] = useState(false);

  const classes = useStyles();

  const fetchOptions = useAction({
    url: request.endpoint,
    params: request.params
  });

  const options = fetchOptions.data
    ? request.dataPath
      ? get(fetchOptions.data, request.dataPath)
      : fetchOptions.data
    : [];

  const getSearchAttr = option => {
    return get(option, searchableFieldPath || displayAttr || '');
  };

  const optionsFilter = option => {
    const val = getSearchAttr(option);
    return (
      val
        .toString()
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) !== -1
    );
  };

  const filteredOptions =
    !searchable || !searchQuery ? options : options.filter(optionsFilter);

  let initialOptionValue = null;

  if (selectedOptionAttr && parentRecord) {
    initialOptionValue = get(parentRecord, selectedOptionAttr);
  }

  const onOpen = () => {
    if (!fetchOptions.data && !fetchOptions.loading) {
      fetchOptions.execute();
    }
  };

  const loading = fetchOptions.loading;
  const currentItem = newSelectedItem || (cleared ? null : initialOptionValue);

  const menuContent = loading ? (
    <Box display="flex" alignItems="center" justifyContent="center">
      <TableLoader width={150} />
    </Box>
  ) : (
    [
      searchable && (
        <Box key="search" p={2}>
          <TextField
            placeholder="Search..."
            fullWidth
            size="small"
            onClick={e => e.stopPropagation()}
            onKeyDown={e => {
              // Prevent MUI-Autoselect while typing
              e.stopPropagation();
            }}
            value={searchQuery || ''}
            onChange={e => {
              setSearchQuery(e.target.value);
            }}
          />
        </Box>
      ),
      <MenuItem value={'__empty__'} key="empty">
        No selection
      </MenuItem>,
      ...filteredOptions.map((option, index) => (
        <MenuItem value={option} key={index}>
          {optionWidget ? (
            <Widget attrs={selectedWidget} data={option} />
          ) : (
            <div>{get(option, displayAttr, '')}</div>
          )}
        </MenuItem>
      ))
    ]
  );

  return (
    <FormControl variant="outlined" fullWidth size={size} error={!!error}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        classes={{
          root: classes.selectRoot
        }}
        MenuProps={{
          classes: {
            paper: classes.menu
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
        onOpen={onOpen}
        label={label}
        displayEmpty
        renderValue={() => {
          return currentItem ? (
            selectedWidget ? (
              <Widget attrs={selectedWidget} data={currentItem} />
            ) : (
              get(currentItem, displayAttr, '')
            )
          ) : (
            ''
          );
        }}
        value={''}
        onChange={event => {
          const newValue = event.target.value;
          if (newValue === '__empty__') {
            setNewSelectedItem(null);
            rest.onChange(null);
            setCleared(true);
            return;
          }
          setNewSelectedItem(newValue);
          const finalVal = newValue ? get(newValue, valueAttr || 'id') : null;
          rest.onChange(finalVal);
          setCleared(false);
        }}
      >
        {menuContent}
      </Select>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
});
