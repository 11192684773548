import * as yup from 'yup';

// yup.addMethod(yup.number, 'optional', function(
//   isOptional = true,
//   defaultValue = undefined
// ) {
//   return (
//     this.transform(function(value) {
//       // If false is passed, skip the transform
//       if (!isOptional) return value;

//       console.log('values', value);

//       return defaultValue;
//     })
//       // Remember, since we're dealing with the `object`
//       // type, we have to change the default value
//       .default(defaultValue)
//   );
// });

yup.addMethod(yup.object, 'optional', function(
  isOptional = true,
  defaultValue = undefined
) {
  return (
    this.transform(function(value) {
      // If false is passed, skip the transform
      if (!isOptional) return value;

      // If any child property has a value, skip the transform
      if (
        value &&
        Object.values(value).some(
          v => !(v === null || v === undefined || v === '')
        )
      ) {
        return value;
      }

      return defaultValue;
    })
      // Remember, since we're dealing with the `object`
      // type, we have to change the default value
      .default(defaultValue)
  );
});

export const main = {
  title: 'Main info',
  initialValues: {
    screen_name: '',
    featured: false
  },
  schema: yup.object().shape({
    screen_name: yup.string().required(),
    user: yup
      .object()
      .shape({
        email: yup
          .string()
          .email()
          .required()
      })
      .optional()
  }),
  cols: {
    main: {
      sections: [
        {
          title: 'Main details',
          fields: [
            {
              label: 'Name',
              name: 'screen_name'
            },
            {
              label: 'Company Description',
              name: 'company_description',
              type: 'richText'
            },
            {
              label: 'Phone',
              name: 'phone'
            },
            {
              label: 'PEC',
              name: 'pec'
            }
          ]
        },
        {
          title: 'Business Address',
          fields: [
            {
              label: 'Address',
              name: 'address'
            },
            {
              label: 'Locality',
              name: 'company_locality'
            },
            {
              label: 'Postal code',
              name: 'zipcode'
            }
          ]
        },
        {
          title: 'Images',
          fields: [
            {
              name: 'images',
              type: 'hasMany',
              includeIds: true,
              layout: 'horizontalList',
              cellHeight: 320,
              fields: [
                {
                  name: 'path',
                  type: 'image',
                  request: {
                    endpoint: 'image-uploads',
                    method: 'POST'
                  },
                  response: {
                    previewUrlPath: 'url',
                    valuePath: 'path'
                  },
                  initialUrlAttr: 'url',
                  span: 4,
                  width: 140,
                  height: 140
                }
              ]
            }
          ]
        },
        {
          title: 'Account',
          fields: [
            {
              name: 'user',
              type: 'hasOne',
              fields: [
                {
                  label: 'Active',
                  name: 'active',
                  type: 'switch'
                },
                {
                  label: 'Email',
                  name: 'email'
                },
                {
                  label: 'Password',
                  name: 'password',
                  type: 'password'
                }
              ]
            }
          ]
        }
      ]
    },
    secondary: {
      sections: [
        {
          title: 'Logo',
          fields: [
            {
              name: 'logo',
              type: 'hasOne',
              fields: [
                {
                  name: 'path',
                  type: 'image',
                  initialUrlAttr: 'url',
                  request: {
                    endpoint: 'image-uploads',
                    method: 'POST'
                  },
                  response: {
                    previewUrlPath: 'url',
                    valuePath: 'path'
                  },
                  width: 200,
                  height: 200
                }
              ]
            }
          ]
        },
        {
          title: 'Promotions',
          fields: [
            {
              label: 'Featured',
              name: 'featured',
              type: 'switch'
            }
          ]
        }
      ]
    }
  }
};

export const account = {
  title: 'Account',
  initialValues: {
    generate_password: 'yes',
    password: 'whatever'
  },
  sections: [
    {
      title: 'Reset password',
      conditions: {
        manual_password_selected: {
          generate_password: 'no'
        }
      },
      fields: [
        {
          name: 'generate_password',
          type: 'radioGroup',
          row: true,
          options: [
            { label: 'Generate new password automatically', value: 'yes' },
            { label: 'Set new password manually', value: 'no' }
          ]
        },
        {
          showOnlyIf: 'manual_password_selected',
          label: 'New password',
          name: 'password',
          InputProps: {
            type: 'password'
          }
        },
        {
          label: 'Confirm password',
          name: 'password_confirm',
          InputProps: {
            type: 'password'
          }
        }
      ]
    }
  ]
};
