import * as fieldset from './fieldset';

export default {
  title: 'Create Supplier',
  afterSave: {
    redirect: {
      resourceIdPath: 'data.id'
    }
  },
  elements: [
    {
      type: 'createForm',
      config: {
        actions: {
          save: {
            endpoint: 'suppliers',
            method: 'POST'
          }
        },
        fieldsets: [fieldset.main]
      }
    }
  ]
};
