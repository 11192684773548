import {
  editMainFieldset,
  editAddressFieldset,
  editStatsFieldset,
  editAccountFieldset
} from './fieldset';

export default {
  title: 'Edit Business',
  actions: {
    fetch: {
      endpoint: 'businesses/{id}',
      method: 'GET',
      params: {
        expand: 'address__province,logo,bowl_stats,user'
      }
    }
  },
  elements: [
    {
      type: 'editForm',
      config: {
        actions: {
          save: {
            endpoint: 'businesses/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [editMainFieldset]
      }
    },
    {
      type: 'editForm',
      config: {
        actions: {
          save: {
            endpoint: 'bowl-stats/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [editStatsFieldset]
      }
    },
    {
      type: 'editForm',
      config: {
        actionContext: {
          id: 'addressId'
        },
        actions: {
          save: {
            endpoint: 'addresses/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [editAddressFieldset]
      }
    },
    {
      type: 'editForm',
      config: {
        actionContext: {
          id: 'userId'
        },
        actions: {
          save: {
            endpoint: 'users/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [editAccountFieldset]
      }
    }
  ]
};
