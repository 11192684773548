import React, { createContext } from 'react';

const DataTableContext = createContext({});

export const DataTableContextProvider = ({ data, children }) => {
  return (
    <DataTableContext.Provider value={data}>
      {children}
    </DataTableContext.Provider>
  );
};

export const TableContextConsumer = DataTableContext.Consumer;

export default DataTableContext;
