import { relatedProductPicker } from '../../product/pickers';

export default {
  value: 'product_carousel',
  label: 'Product carousel',
  fields: [
    {
      label: 'Title',
      name: 'title'
    },
    {
      name: 'items',
      type: 'hasMany',
      includeIds: false,
      collapsible: false,
      layout: 'horizontalList',
      cellHeight: 350,
      fields: [
        {
          name: '$item.type',
          type: 'hidden',
          fixedValue: 'product'
        },
        {
          name: '$item.id',
          type: 'relatedPicker',
          label: 'Featured product',
          initialValuePath: '$item.object',
          title: 'Select a product',
          viewConfig: relatedProductPicker
        },
        {
          name: 'subtitle',
          label: 'Subtitle'
        }
      ]
    }
  ]
};
