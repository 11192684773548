import { createFieldset } from './fieldset';

export default {
  title: 'Modifica tipologia Bowl',
  elements: [
    {
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'bowl-types/{id}',
            method: 'GET'
          },
          save: {
            endpoint: 'bowl-types/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [createFieldset]
      }
    }
  ]
};
