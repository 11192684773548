import React, { useState } from 'react';

import {
  Box,
  InputLabel,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core';
import { get } from 'lodash';
import FormField from '../FormField';
import { Controller, useFormContext } from 'react-hook-form';

const RecordCard = ({
  namePrefix,
  includeId = true,
  model,
  fields,
  onRemove,
  defaultValue
}) => {
  return (
    <Box component={Card} mb={2}>
      <CardContent>
        {includeId && (
          <Controller
            as={'input'}
            type="hidden"
            name={`${namePrefix}.id`}
            defaultValue={model ? model.id || '' : ''}
          />
        )}

        <Grid container spacing={3}>
          {fields.map((field, index) => {
            return (
              <Grid item md={12} xs={12} key={index}>
                <FormField
                  {...field}
                  model={model}
                  defaultValue={get(defaultValue, field.name)}
                  namePrefix={namePrefix}
                  parentRecord={model}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>

      <CardActions>
        <Button color="primary" onClick={onRemove}>
          Remove
        </Button>
      </CardActions>
    </Box>
  );
};

// eslint-disable-next-line react/display-name
const HasOneField = React.forwardRef(
  (
    {
      label,
      name,
      singleName,
      fields,
      parentRecord,
      includeId,
      defaultValue,
      model
    },
    ref
  ) => {
    const { setValue, watch } = useFormContext();
    const [createdNew, setCreatedNew] = useState(false);
    const [deleted, setDeleted] = useState(false);

    const deleteKey = `${name}.$deleted`;

    const hasBeenDeleted = watch(deleteKey, '');
    const initialParentRecordVal = parentRecord
      ? get(parentRecord, name)
      : null;

    const modelVal = parentRecord ? get(parentRecord, singleName) : null;

    const onAdd = () => {
      // TODO: do we need this?
      // setValue(deleteKey, '', { shouldDirty: true });
      setDeleted(false);

      if (!initialParentRecordVal) {
        setCreatedNew(true);
        fields.forEach(field => {
          const fullName = `${name}.${field.name}`;
          const val = '';
          setValue(fullName, val, { shouldDirty: true });
        });
      }
    };

    const onRemove = () => {
      fields.forEach(field => {
        const fullName = `${name}.${field.name}`;
        const val = field.type === 'image' ? '_deleted_' : '';
        setValue(fullName, val, { shouldDirty: true });
      });

      setValue(deleteKey, 'true');
      setDeleted(true);
      setCreatedNew(false);
    };

    const showNestedFields =
      !deleted &&
      (createdNew || ((initialParentRecordVal || modelVal) && !hasBeenDeleted));

    return (
      <Box>
        <Box mb={1}>
          <InputLabel shrink>{label}</InputLabel>
        </Box>

        {showNestedFields ? (
          <RecordCard
            namePrefix={name}
            includeId={includeId}
            defaultValue={defaultValue}
            model={initialParentRecordVal}
            fields={fields}
            onRemove={onRemove}
          />
        ) : (
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            padding={4}
            bgcolor="#f4f6f8"
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={onAdd}
              size="small"
            >
              Add
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

export default HasOneField;
