import blockBgColorField from '../fields/blockBgColorField';
import infoBoxItemsField from '../fields/infoBoxItemsField';
import ctaField from '../fields/ctaField';

export default {
  value: 'info_box',
  label: 'Info box',
  fields: [
    blockBgColorField,
    {
      label: 'Title',
      name: 'title'
    },
    {
      label: 'Content',
      name: 'content',
      type: 'richText'
    },
    infoBoxItemsField,
    ctaField,
    {
      label: 'Codename (Block identifier)',
      name: 'codename',
      type: 'select',
      options: [
        { label: 'Partners', value: 'content-block-partners' },
      ]
    },
  ]
};
