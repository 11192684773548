export const supplierListFilters = {
  fields: [
    {
      label: 'Search',
      name: 'filter[screen_name]',
      size: 'small',
      span: 4,
      bgColor: 'white'
    }
  ]
};

export const membershipPriceSelect = {
  name: 'membership_price_id',
  type: 'lazySelect',
  displayAttr: 'screen_name',
  selectedOptionAttr: 'price',
  request: {
    endpoint: 'membership-prices',
    dataPath: 'data'
  },
  selectedWidget: {
    widget: 'card',
    main: 'product.name',
    secondary: {
      field: 'amount',
      widget: 'monetaryAmount',
      currency: 'currency',
      size: 'small'
    }
  },
  optionWidget: {
    widget: 'card',
    main: 'amount',
    secondary: 'product.name'
  }
};
