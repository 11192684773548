import * as yup from 'yup';
import { businessSelect } from '../business/shared';

export const createFieldset = {
  title: 'Dettagli',
  initialValues: {
    title: '',
    description: ''
  },
  schema: yup.object().shape({
    title: yup.string().required()
  }),
  sections: [
    {
      title: 'Dati principali',
      fields: [
        {
          ...businessSelect
        },
        {
          label: 'Nome',
          name: 'name'
        },
        {
          label: 'Immagine',
          name: 'mainImage',
          type: 'image',
          initialUrlAttr: 'mainImage.file',
          width: 200,
          height: 200,
          request: {
            endpoint: 'images',
            method: 'POST'
          },
          response: {
            previewUrlPath: 'file',
            valuePath: 'id'
          }
        },
        {
          label: 'Descrizione',
          name: 'richText'
        },
        {
          label: 'Prezzo',
          name: 'price'
        }
      ]
    }
  ]
};

export const editFieldset = {
  title: 'Dettagli',
  initialValues: {
    name: '',
    description: ''
  },
  schema: yup.object().shape({
    title: yup.string().required()
  }),
  sections: [
    {
      title: 'Dati principali',
      fields: [
        {
          ...businessSelect,
          name: 'businessId'
        },
        {
          label: 'Nome',
          name: 'name'
        },
        {
          label: 'Immagine',
          name: 'mainImageId',
          type: 'image',
          initialUrlAttr: 'mainImage.file',
          width: 200,
          height: 200,
          request: {
            endpoint: 'images',
            method: 'POST'
          },
          response: {
            previewUrlPath: 'file',
            valuePath: 'id'
          }
        },
        {
          label: 'Descrizione',
          name: 'richText'
        },
        {
          label: 'Prezzo',
          name: 'price'
        }
      ]
    }
  ]
};
