import blockBgColorField from '../fields/blockBgColorField';
import blockImageField from '../fields/blockImageField';
import ctaField from '../fields/ctaField';

export default {
  value: 'broken_cols',
  label: 'Broken columns design',
  fields: [
    blockBgColorField,
    {
      label: 'Title',
      name: 'title'
    },
    {
      label: 'Subtitle',
      name: 'subtitle'
    },
    {
      ...blockImageField,
      label: 'First Image',
      name: 'first_image_path'
    },
    {
      ...ctaField,
      label: 'First call to action',
      name: 'first_cta'
    },
    {
      ...blockImageField,
      label: 'Second Image',
      name: 'second_image_path'
    },
    {
      ...ctaField,
      label: 'Second call to action',
      name: 'second_cta'
    }
  ]
};
