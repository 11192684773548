import heroBgField from '../fields/heroBgField';

export default {
  value: 'hero_with_box',
  label: 'Hero With Content Box',
  fields: [
    {
      label: 'Title',
      name: 'title'
    },
    {
      label: 'Content',
      name: 'content',
      type: 'richText'
    },
    heroBgField
  ]
};
