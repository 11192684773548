import React from 'react';
import { Grid } from '@material-ui/core';

import Fieldsets from '../Fieldsets';

const FormContent = ({ fieldset, initialValues, variant }) => {
  const { sections, cols } = fieldset;

  let content;

  if (cols) {
    content = (
      <Grid container spacing={3}>
        <Grid item md={9} xs={12}>
          <Fieldsets
            sections={cols.main.sections}
            initialValues={initialValues}
            variant={variant}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Fieldsets
            secondary
            sections={cols.secondary.sections}
            initialValues={initialValues}
            variant={variant}
          />
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <Fieldsets
        sections={sections}
        initialValues={initialValues}
        variant={variant}
      />
    );
  }

  return content;
};

export default FormContent;
