import { supplierListFilters } from './shared';
export default {
  title: 'Elenco Supplier',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'suppliers',
        defaultParams: {
          sort: '-created_at'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        filters: supplierListFilters,

        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Name',
            sortable: true,
            widget: 'card',
            main: {
              widget: 'text',
              field: 'screen_name',
              variant: 'h5'
            },
            image: {
              widget: 'logo',
              field: 'logo_url'
            },
            secondary: {
              field: 'activeSubscription.price.product.name'
            }
          },
          {
            label: 'Locality',
            field: 'company_locality'
          },
          {
            label: 'Num. prodotti',
            field: 'products_count'
          },
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          }
        ]
      }
    }
  ]
};
