import blockBgColorField from '../fields/blockBgColorField';

export default {
  value: 'two_cols_text',
  label: 'Two cols text',
  fields: {
    left: [
      {
        label: 'Title',
        name: 'title'
      },
      {
        label: 'Left col content',
        name: 'content_left',
        type: 'richText'
      }
    ],
    right: [
      blockBgColorField,
      {
        label: 'Right col content',
        name: 'content_right',
        type: 'richText'
      }
    ]
  }
};
