import React, { useEffect } from 'react';

import { get } from 'lodash';
import FormPage from 'src/components/pages/FormPage';
import expandString from 'expand-template';

import ViewElements from '../ViewElements';
import { useParams } from 'react-router-dom';
import useAction from 'src/hooks/useAction';
import { DataContextProvider } from 'src/contexts/DataContext';

const stringExpander = expandString();

const EditView = ({ config, resourceSlug, resourceId }) => {
  const { appName } = useParams();

  const shouldFetchData = !!get(config, 'actions.fetch');

  const actionParams = {
    fetch: shouldFetchData
      ? {
          url: stringExpander(config.actions.fetch.endpoint, {
            id: resourceId
          }),
          method: config.actions.fetch.method,
          params: config.actions.fetch.params
        }
      : {}
  };

  const actions = {
    fetch: useAction(actionParams.fetch)
  };

  useEffect(() => {
    if (shouldFetchData) {
      actions.fetch.execute();
    }
    // FIXME: needed cause actions are recreated!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadCrumbs = [
    { label: config.title, to: `/${appName}/app/${resourceSlug}/list` }
  ];

  const data = actions.fetch.data;

  return (
    <FormPage title={config.title} breadcrumbs={breadCrumbs}>
      <DataContextProvider data={data}>
        <ViewElements
          resourceSlug={resourceSlug}
          resourceId={resourceId}
          elements={config.elements}
        />
      </DataContextProvider>
    </FormPage>
  );
};

export default EditView;
