import useApiClient from 'src/hooks/useApiClient';
import useAxios, { configure } from 'axios-hooks';

const useAction = params => {
  const { client } = useApiClient();

  configure({ axios: client });

  const [status, execute] = useAxios(params, { manual: true });

  return { ...status, execute };
};

export default useAction;
