import { editFieldset } from './fieldset';

export default {
  title: 'Modifica Offerta',
  elements: [
    {
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'offers/{id}',
            method: 'GET',
            params: {
              expand: 'product__main_image'
            }
          },
          save: {
            endpoint: 'offers/{id}',
            method: 'PATCH'
          }
        },
        fieldsets: [editFieldset]
      }
    }
  ]
};
