import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import useCurrentResource from 'src/hooks/useCurrentResource';

const EditActionButton = ({ action }) => {
  const { appName } = useParams();

  const currentResource = useCurrentResource();

  const to = `/${appName}/app/${currentResource.slug}/edit/${currentResource.id}`;

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      startIcon={<CreateOutlinedIcon />}
      component={RouterLink}
      to={to}
    >
      {action.label}
    </Button>
  );
};

const DeleteActionButton = ({ action }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      startIcon={<DeleteOutlinedIcon />}
    >
      {action.label}
    </Button>
  );
};

const ACTION_TYPE_MAP = {
  edit: EditActionButton,
  delete: DeleteActionButton
};

const ActionButton = ({ action }) => {
  const Cmp = ACTION_TYPE_MAP[action.type];

  return <Cmp action={action} />;
};

const Actions = ({ actions }) => {
  return (
    <React.Fragment>
      {actions.map((action, index) => (
        <Box mr={2} key={index}>
          <ActionButton action={action} />
        </Box>
      ))}
    </React.Fragment>
  );
};

export default Actions;
