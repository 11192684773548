import { productListFilters } from '../product/shared';

export const relatedProductPicker = {
  type: 'dataTable',
  url: 'products',
  defaultParams: {
    sort: '-created_at'
  },
  response: {
    mappers: {
      list: 'data'
    }
  },
  tabs: [],
  selection: {
    multiple: false,
    valueSelector: 'id',
    selectedWidget: {
      widget: 'card',
      main: 'name',
      image: {
        field: 'listing_image_url',
        width: 75,
        height: 75
      }
    }
  },
  filters: productListFilters,
  pagination: {
    mappers: {
      count: 'meta.total',
      perPage: 'meta.per_page'
    }
  },
  columns: [
    {
      label: 'Name',

      filter: {
        type: 'text',
        param: 'filter[name]'
      },
      sortable: true,

      widget: 'card',
      main: 'name',
      image: {
        field: 'listing_image_url',
        width: 75,
        height: 75
      }
    },
    {
      label: 'Supplier',
      field: 'supplier.logo_url',
      align: 'center',
      widget: 'logo'
    },
    {
      label: 'Created',
      field: 'created_at',
      widget: 'datetime',
      sortable: true
    }
  ]
};
