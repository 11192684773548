export default {
  title: 'Transazioni',
  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'bowl-transactions',
        defaultParams: {
          // expand: 'address__province,logo'
        },
        response: {
          mappers: {
            list: 'results'
          }
        },
        pagination: {
          mappers: {
            count: 'count'
          }
        },
        columns: [
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Identificativo interno',
            field: 'codename'
          }
        ]
      }
    }
  ]
};
