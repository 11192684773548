export default {
  items: [
    {
      subheader: 'Inventario',
      items: [
        {
          title: 'Prodotti',
          icon: 'Archive',
          href: '/app/product/list',
          items: [
            {
              title: 'Elenco Prodotti',
              href: '/app/product/list'
            },
            {
              title: 'Aggiungi Prodotto',
              href: '/app/product/create'
            }
          ]
        },
        {
          title: 'Categorie',
          icon: 'ClassOutlined',
          href: '/app/category/list',
          items: [
            {
              title: 'Elenco categorie',
              href: '/app/category/list'
            },
            {
              title: 'Aggiungi categoria',
              href: '/app/category/create'
            }
          ]
        }
      ]
    },
    {
      subheader: 'Marketplace',
      items: [
        {
          title: "Richieste d'ordine",
          icon: 'Archive',
          href: '/app/orderrequest/list',
          items: [
            {
              title: 'Elenco richieste',
              href: '/app/orderrequest/list'
            }
          ]
        },
        {
          title: 'Conversazioni',
          icon: 'MessageSquare',
          href: '/app/conversation/list',
          items: [
            {
              title: 'Elenco conversazioni',
              href: '/app/conversation/list'
            },
            {
              title: 'Elenco messaggi',
              href: '/app/message/list'
            }
          ]
        }
      ]
    },
    {
      subheader: 'Memberships',
      items: [
        {
          title: 'Piani & pricing',
          icon: 'Membership',
          href: '/app/membershipplan/list',
          items: [
            {
              title: 'Prodotti',
              href: '/app/membershipproduct/list'
            },
            {
              title: 'Pricing plans',
              href: '/app/membershipprice/list'
            },
            {
              title: 'Coupons',
              href: '/app/membershipcoupon/list'
            }
          ]
        },
        {
          title: 'Checkouts',
          icon: 'Cart',
          href: '/app/membershipcheckout/list',
          items: [
            {
              title: 'Elenco checkout',
              href: '/app/membershipcheckout/list'
            }
          ]
        }
      ]
    },
    {
      subheader: 'Account',
      items: [
        {
          title: 'Suppliers',
          icon: 'StorefrontOutlined',
          href: '/app/supplier/list',
          items: [
            {
              title: 'Elenco suppliers',
              href: '/app/supplier/list'
            },
            {
              title: 'Aggiungi supplier',
              href: '/app/supplier/create'
            }
          ]
        },
        {
          title: 'Buyers',
          icon: 'Users',
          href: ''
        }
      ]
    },
    {
      subheader: 'Content',
      items: [
        {
          title: 'Pagine',
          icon: 'Page',
          items: [
            {
              title: 'Elenco pagine',
              href: '/app/page/list'
            }
          ]
        },
        {
          title: 'Blog',
          icon: 'Page',
          items: [
            {
              title: 'Elenco post',
              href: '/app/blogpost/list'
            }
          ]
        }
      ]
    }
  ]
};
