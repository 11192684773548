import React from 'react';
import { Box, LinearProgress } from '@material-ui/core';

const TableLoader = ({ width = 400 }) => (
  <Box alignItems="center" justifyContent="center" display="flex" py={8}>
    <Box width={width}>
      <LinearProgress />
    </Box>
  </Box>
);

export default TableLoader;
