import hero from './slides/hero';
import hero_big_text from './slides/hero_big_text';
import hero_text_center from './slides/hero_text_center';
import hero_with_actions from './slides/hero_with_actions';
import main_hero from './slides/main_hero';

export default {
  hero: hero,
  hero_big_text: hero_big_text,
  hero_text_center: hero_text_center,
  hero_with_actions: hero_with_actions,
  main_hero: main_hero
};
