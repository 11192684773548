import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  SvgIcon,
  Typography,
  makeStyles,
  Box
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { PlusCircle as PlusCircleIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const ListHeader = ({ title, breadcrumbs = [], actions = [] }) => {
  const classes = useStyles();

  const breadcrumbsLinks = breadcrumbs.map(({ to, label }, index) => (
    <Link
      key={index}
      variant="body1"
      color="inherit"
      to={to || '/'}
      component={RouterLink}
    >
      {label}
    </Link>
  ));

  const actionButtons = actions.map((entry, index) => (
    <Button
      key={index}
      color="secondary"
      variant="contained"
      className={classes.action}
      component={RouterLink}
      to="/app/management/businesses/create"
      startIcon={
        <SvgIcon fontSize="small">
          {entry.type === 'create' ? <PlusCircleIcon /> : null}
        </SvgIcon>
      }
      {...entry}
    >
      {entry.label}
    </Button>
  ));

  return (
    <Box mb={2}>
      <Grid
        className={classes.root}
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbsLinks}
          </Breadcrumbs>
          <Typography variant="h3" color="textPrimary">
            {title}
          </Typography>
        </Grid>
        <Grid item>{actionButtons}</Grid>
      </Grid>
    </Box>
  );
};

export default ListHeader;
