import heroBgField from '../fields/heroBgField';

export default {
  value: 'hero_with_spaces',
  label: 'Hero With Lateral Spaces',
  fields: [
    {
      label: 'Title',
      name: 'title'
    },
    heroBgField
  ]
};
