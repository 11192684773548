import * as fieldset from './fieldset';

export default {
  title: 'Edit Membership Checkout',
  elements: [
    {
      title: 'Main',
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'membership-checkouts/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'membership-checkouts/{id}',
            method: 'PUT'
          }
        },
        fieldsets: [fieldset.main]
      }
    }
  ]
};
