import React from 'react';
import { take } from 'lodash';
import { getValue } from 'src/dataUtils';
import takeRight from 'lodash/takeRight';

import Label from 'src/components/Label';

const IdentifierLabel = ({ data, field, maxChars = 10 }) => {
  const value = getValue(data, field);

  if (!value || !value.split) {
    return null;
  }
  const chars = value.split('');

  const shortId =
    chars.length <= maxChars
      ? value
      : take(chars, maxChars).join('') +
        '...' +
        takeRight(chars, maxChars).join('');

  return <Label color="secondary">{shortId}</Label>;
};

export default IdentifierLabel;
