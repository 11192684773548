import ctaField from '../fields/ctaField';
import heroBgField from '../fields/heroBgField';

export default {
  value: 'hero_with_actions',
  label: 'Hero with actions',
  fields: [
    {
      label: 'Title',
      name: 'title'
    },
    {
      label: 'Subtitle',
      name: 'subtitle',
      size: 'small'
    },
    ctaField,
    heroBgField
  ]
};
