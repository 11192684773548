import * as yup from 'yup';

const mainFields = [
  {
    label: 'Nome',
    name: 'firstName'
  },
  {
    label: 'Cognome',
    name: 'lastName'
  },
  {
    label: 'Num. di telefono',
    name: 'phone'
  }
];

const addressFields = [
  {
    label: 'Via / Corso / Piazza',
    name: 'streetName'
  },
  {
    label: 'Numero',
    name: 'streetNumber'
  },
  {
    label: 'C.A.P.',
    name: 'postcode'
  },
  {
    label: 'Comune',
    name: 'city'
  },
  {
    label: 'Provincia',
    name: 'provinceId',
    type: 'lazySelect',
    displayAttr: 'name',
    valueAttr: 'id',
    selectedOptionAttr: 'province',
    request: {
      endpoint: 'provinces'
    }
  }
];

const accountFields = [
  {
    label: 'Email',
    name: 'user.email'
  },
  {
    label: 'Password',
    name: 'user.password',
    type: 'password'
  }
];

export const addressFieldset = {
  title: 'Address',
  initialValues: {
    name: ''
  },
  schema: yup.object().shape({
    streetName: yup.string(),
    streetNumber: yup.string(),
    postcode: yup.string(),
    city: yup.string(),
    province: yup.string()
  }),
  sections: [
    {
      title: 'Address',
      fields: addressFields
    }
  ]
};

export const createFieldset = {
  title: 'Main info',
  initialValues: {
    name: ''
  },
  schema: yup.object().shape({
    name: yup.string()
  }),
  sections: [
    {
      title: 'Main details',
      fields: mainFields
    },
    {
      title: 'Address',
      fields: [
        {
          label: 'Via / Corso / Piazza',
          name: 'homeAddress.streetName'
        },
        {
          label: 'Numero',
          name: 'homeAddress.streetNumber'
        },
        {
          label: 'C.A.P.',
          name: 'homeAddress.postcode'
        },
        {
          label: 'Comune',
          name: 'homeAddress.city'
        },
        {
          label: 'Provincia',
          name: 'homeAddress.provinceId',
          type: 'lazySelect',
          displayAttr: 'name',
          valueAttr: 'id',
          selectedOptionAttr: 'province',
          request: {
            endpoint: 'provinces'
          }
        }
      ]
    },
    {
      title: 'Account',
      fields: accountFields
    }
  ]
};

export const editMainFieldset = {
  title: 'Main info',
  initialValues: null,
  schema: yup.object().shape({
    name: yup.string()
  }),
  sections: [
    {
      title: 'Main details',
      fields: mainFields
    }
  ]
};

export const editAddressFieldset = {
  title: 'Address',
  contextDataPrefix: 'homeAddress',
  initialValues: null,
  schema: yup.object().shape({
    // name: yup.string()
  }),
  sections: [
    {
      title: 'Address',
      fields: addressFields
    }
  ]
};
