export default {
  title: 'Tipi di Bowl',
  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'bowl-types',
        defaultParams: {
          // expand: 'address__province,logo'
        },
        columns: [
          {
            label: 'QR Code',
            widget: 'qrCode',
            field: 'qrcodeValue',
            size: 64
          },
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Identificativo interno',
            field: 'codename'
          }
        ]
      }
    }
  ]
};
