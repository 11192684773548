export default {
  title: 'Elenco Coupon Membership',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'membership-coupons',
        response: {
          mappers: {
            list: 'data'
          }
        },

        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          }
        ]
      }
    }
  ]
};
