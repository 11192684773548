import React from 'react';

import FormPage from 'src/components/pages/FormPage';

import ViewElements from '../ViewElements';

const DetailView = ({ config, resourceSlug, resourceId }) => {
  const breadCrumbs = [];
  console.log(config.actions);

  return (
    <FormPage
      title={config.title}
      breadcrumbs={breadCrumbs}
      actions={config.actions}
    >
      <ViewElements
        resourceSlug={resourceSlug}
        resourceId={resourceId}
        elements={config.elements}
      />
    </FormPage>
  );
};

export default DetailView;
