import blockImageField from './blockImageField';

export default {
  name: 'items',
  type: 'hasMany',
  includeIds: false,
  collapsible: false,
  layout: 'horizontalList',
  cellHeight: 550,
  fields: [
    {
      ...blockImageField,
      width: 150,
      height: 150
    },
    {
      name: 'title',
      label: 'Title'
    },
    {
      label: 'Content',
      name: 'content',
      type: 'richText'
    }
  ]
};
