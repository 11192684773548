import ctaField from '../fields/ctaField';
import blockBgColorField from '../fields/blockBgColorField';
import blockImageField from '../fields/blockImageField';

export default {
  value: 'generic_carousel',
  label: 'Generic carousel',
  fields: [
    blockBgColorField,
    {
      label: 'Pretitle',
      name: 'pretitle'
    },
    {
      label: 'Title',
      name: 'title'
    },
    {
      name: 'items',
      type: 'hasMany',
      includeIds: false,
      collapsible: false,
      layout: 'horizontalList',
      cellHeight: 550,
      fields: [
        {
          ...blockImageField,
          width: 150,
          height: 150
        },
        {
          label: 'Caption',
          name: 'caption'
        },
        {
          label: 'Subtitle',
          name: 'subtitle'
        }
      ]
    },
    ctaField
  ]
};
