import React from 'react';

import { Box } from '@material-ui/core';
import Slot from './Slot';

const Card = ({ data, main, image, secondary, tertiary }) => {
  const titleNode = <Slot data={data} attr={main} widgetType="text" />;
  const secondaryNode = secondary && <Slot data={data} attr={secondary} />;
  const tertiaryNode = tertiary && <Slot data={data} attr={tertiary} />;

  const imageNode = image && (
    <Slot data={data} attr={image} widgetType="thumb" />
  );

  return (
    <Box display="flex" alignItems="center">
      {imageNode && <Box mr={2}>{imageNode}</Box>}
      <Box>
        {titleNode}
        {secondaryNode}
        {tertiaryNode && <Box mt={1}>{tertiaryNode}</Box>}
      </Box>
    </Box>
  );
};

export default Card;
