import React from 'react';

import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';

export default React.forwardRef(function SwitchField(
  { label, onChange, value, options = [] },
  ref
) {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange}>
        {options.map((option, optionIndex) => {
          return (
            <MenuItem value={option.value} key={optionIndex}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});
