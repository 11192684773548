import React, { createContext } from 'react';

const ResourceContext = createContext({
  resource: null
});

export const ResourceContextProvider = ({ children, resource }) => {
  return (
    <ResourceContext.Provider value={resource}>
      {children}
    </ResourceContext.Provider>
  );
};

export const ResourceContextConsumer = ResourceContext.Consumer;

export default ResourceContext;
