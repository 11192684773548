import { get, mapValues, isPlainObject, isString } from 'lodash';
import expandString from 'expand-template';
import nunjucks from 'nunjucks';

const stringExpander = expandString();

export const extract = (data, path) => get(data, path);

export const parsePaths = (attrs, data) =>
  mapValues(attrs, value => get(data, value));

export const expandParams = (params, context) =>
  mapValues(params, val => stringExpander(val, context));

export const renderTemplate = (template, context = {}) => {
  return nunjucks.renderString(template, context);
};

export const getValue = (data, field, defaultValue) => {
  if (isString(field)) {
    return get(data, field, defaultValue);
  }

  if (isPlainObject(field) && field.type === 'template') {
    return renderTemplate(field.template, {
      item: data
    });
  }

  console.warn('Unknown attribute format', field);
};
