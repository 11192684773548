// import { businessSelect } from '../business/shared';

export default {
  title: 'Offerte',
  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'offers',
        // filters: {
        //   fields: [
        //     {
        //       ...businessSelect,
        //       selectedWidget: {
        //         widget: 'card',
        //         main: 'name',
        //         image: {
        //           field: 'logo.file',
        //           width: 30,
        //           height: 30
        //         }
        //       },
        //       span: 3,
        //       name: 'business'
        //     },
        //     {
        //       label: 'Ricerca',
        //       span: 7,
        //       name: 'name'
        //     }
        //   ]
        // },
        response: {
          mappers: {
            list: 'results'
          }
        },
        defaultParams: {
          expand: 'product__business__logo'
        },
        columns: [
          {
            label: 'Product',
            widget: 'card',
            main: 'name',
            image: {
              field: 'main_image.file',
              width: 50,
              height: 50
            }
          },
          {
            label: 'Aggiunto il',
            field: 'created',
            widget: 'datetime'
          },
          {
            label: 'Business',
            widget: 'card',
            main: 'business.name',
            image: {
              field: 'business.logo.file',
              width: 50,
              height: 50
            }
          },
          {
            label: 'Name',
            field: 'name'
          }
        ]
      }
    }
  ]
};
