import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { getAppConfig } from 'src/generics/dataUtils';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: props => (props.dark ? theme.palette.primary.dark : 'white')
  },
  brandName: {
    marginLeft: 10,
    textDecoration: 'none',
    fontSize: 16
  }
}));

const Logo = ({ dark }) => {
  const classes = useStyles({ dark });
  const { appName } = useParams();

  const { branding } = getAppConfig(appName);

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.brandName}>
        {branding.logo}
      </Typography>
    </div>
  );
};

export default Logo;
