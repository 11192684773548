import React from 'react';
import { getValue } from 'src/dataUtils';
import { Chip } from '@material-ui/core';

const MonetaryAmount = ({ data, field, currency = '', size }) => {
  const value = getValue(data, field);
  const parsedCurrency = getValue(data, currency);

  let finalValue;

  if (typeof value === undefined || value === null) {
    finalValue = value;
  } else {
    finalValue = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: parsedCurrency
    }).format(value);
  }

  return <Chip label={finalValue} variant="outlined" size={size} />;
};

export default MonetaryAmount;
