import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';

import DetailHeader from 'src/components/pages/DetailHeader';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const FormPage = ({ title, breadcrumbs, children, actions }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title={title}>
      {breadcrumbs && (
        <Container>
          <DetailHeader
            title={title}
            breadcrumbs={breadcrumbs}
            actions={actions}
          />
        </Container>
      )}
      <Box mt={3}>
        {children && <Container maxWidth="lg">{children}</Container>}
      </Box>
    </Page>
  );
};

export default FormPage;
