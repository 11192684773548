import { relatedSupplierPicker } from '../../supplier/pickers';
import blockBgColorField from '../fields/blockBgColorField';

export default {
  value: 'supplier_carousel',
  label: 'Supplier carousel',
  fields: [
    blockBgColorField,
    {
      label: 'Pretitle',
      name: 'pretitle'
    },
    {
      label: 'Title',
      name: 'title'
    },
    {
      name: 'items',
      type: 'hasMany',
      includeIds: false,
      collapsible: false,
      layout: 'horizontalList',
      cellHeight: 350,
      fields: [
        {
          name: '$item.type',
          type: 'hidden',
          fixedValue: 'supplier'
        },
        {
          name: '$item.id',
          type: 'relatedPicker',
          label: 'Featured supplier',
          initialValuePath: '$item.object',
          title: 'Select a supplier',
          viewConfig: relatedSupplierPicker
        },
        {
          name: 'categories',
          label: 'Categories'
        }
      ]
    }
  ]
};
