import React from 'react';
import moment from 'moment';
import { getValue } from 'src/dataUtils';
import { Typography } from '@material-ui/core';

const Datetime = ({ data, field, emptyVal = '-' }) => {
  const value = getValue(data, field, emptyVal);
  const content = value ? moment(value).format('D MMM YY - h:mm a') : emptyVal;

  return <Typography variant="caption">{content}</Typography>;
};

export default Datetime;
