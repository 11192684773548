import _ from 'lodash';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { getAppConfig } from 'src/generics/dataUtils';
import typography from './typography';

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

export const createTheme = appName => {
  const appConfig = getAppConfig(appName);

  let themeOptions = appConfig.theme;

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: 'ltr' })
  );

  if (themeOptions.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
