import * as fieldset from './fieldset';

export default {
  title: 'Create Membership Pricing Plan',
  elements: [
    {
      type: 'createForm',
      config: {
        actions: {
          save: {
            endpoint: 'membership-prices',
            method: 'POST'
          }
        },
        fieldsets: [fieldset.main]
      }
    }
  ]
};
