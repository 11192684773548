import React, { useState, useEffect } from 'react';

import {
  Box,
  InputLabel,
  Grid,
  Card,
  Button,
  CardContent,
  CardActions,
  GridList,
  GridListTile,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardHeader,
  Collapse,
  Divider
} from '@material-ui/core';
import { get } from 'lodash';

import {
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronUp
} from 'react-feather';

import DeleteIcon from '@material-ui/icons/Delete';
import FormField from '../FormField';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useCardStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: '#f4f6f8',
    padding: 8
  },
  gridList: {
    flexWrap: 'nowrap',
    flex: 1,
    transform: 'translateZ(0)'
  }
}));

const OneColCard = ({
  nestedNamePrefix,
  includeIds,
  collapsible,
  control,
  model,
  fields,
  remove,
  move,
  modelIndex,
  isLast,
  selectedTypeOption,
  direction,
  orderable
}) => {
  const title = selectedTypeOption ? selectedTypeOption.label : null;

  const classes = useCardStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderFields = (
    fields,
    WrapCmp = React.Fragment,
    wrapCmpProps = {}
  ) => {
    return (
      <React.Fragment>
        {fields.map((field, fieldIndex) => {
          return (
            <WrapCmp key={fieldIndex} {...wrapCmpProps}>
              <FormField
                {...field}
                namePrefix={nestedNamePrefix}
                defaultValue={get(model, field.name)}
                parentRecord={model}
                Wrapper={{
                  Cmp: Grid,
                  props: { item: true, md: field.span || 12, xs: 12 }
                }}
              />
            </WrapCmp>
          );
        })}
      </React.Fragment>
    );
  };

  const header = title ? (
    <Box display="flex" alignItems="center">
      {collapsible && (
        <Box mr={2}>
          <IconButton
            size="small"
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      )}
      <Box>{title}</Box>
    </Box>
  ) : null;

  let fieldContent;

  if (fields.left || fields.right) {
    const wrapCmpProps = {
      item: true,
      md: 12,
      xs: 12
    };

    fieldContent = (
      <React.Fragment>
        <Grid item md={6} xs={12}>
          <Grid container spacing={3}>
            {renderFields(fields.left, Grid, wrapCmpProps)}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={3}>
            {renderFields(fields.right, Grid, wrapCmpProps)}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    fieldContent = renderFields(fields);
  }

  let cardContent = (
    <CardContent>
      {includeIds && (
        <Controller
          as={'input'}
          type="hidden"
          name={`${nestedNamePrefix}.id`}
          control={control}
          defaultValue={model.id || ''}
        />
      )}

      {orderable && (
        <Controller
          as={'input'}
          type="hidden"
          name={`${nestedNamePrefix}.position`}
          control={control}
          defaultValue="0"
        />
      )}

      <Grid container spacing={3}>
        {fieldContent}
      </Grid>
    </CardContent>
  );

  if (collapsible) {
    cardContent = (
      <Collapse in={expanded} timeout="auto">
        {cardContent}
      </Collapse>
    );
  }

  const MovePrevIcon = direction === 'horizontal' ? ChevronLeft : ChevronUp;
  const MoveNextIcon = direction === 'horizontal' ? ChevronRight : ChevronDown;

  return (
    <Box component={Card} mb={2}>
      {header && <CardHeader title={header} />}

      {cardContent}

      <Divider />

      <CardActions>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => {
            remove(modelIndex);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            move(modelIndex, modelIndex - 1);
          }}
          disabled={!modelIndex}
        >
          <MovePrevIcon fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            move(modelIndex, modelIndex + 1);
          }}
          disabled={isLast}
        >
          <MoveNextIcon fontSize="small" />
        </IconButton>
      </CardActions>
    </Box>
  );
};

const VerticalCardListLayout = ({
  formFields,
  name,
  cols,
  fields,
  typeSelector,
  control,
  ...rest
}) => {
  return (
    <React.Fragment>
      {formFields.map((model, modelIndex) => {
        const nestedNamePrefix = `${name}[${modelIndex}]`;

        const cardProps = {
          ...rest,
          cols,
          fields,
          typeSelector,
          model,
          nestedNamePrefix,
          control,
          modelIndex,
          isLast: modelIndex === formFields.length - 1,
          direction: 'vertical'
        };

        // const Cmp = fields ? OneColCard : TwoColsCard;
        const Cmp = OneColCard;

        return <CardWrapper Cmp={Cmp} {...cardProps} key={model._id} />;

        // return <OneColCard {...cardProps} key={model._id} />;
      })}
    </React.Fragment>
  );
};

const CardWrapper = ({
  Cmp,
  control,
  nestedNamePrefix,
  model,
  fields,
  typeSelector,
  ...rest
}) => {
  let cardFields;
  let selectedTypeOption;

  if (!typeSelector) {
    cardFields = fields;
  } else {
    selectedTypeOption = typeSelector.options.find(
      opt => opt.value === model[typeSelector.typeFieldName]
    );

    if (!selectedTypeOption) {
      console.error(
        `Unknown field type: ${
          model[typeSelector.typeFieldName]
        }. Available options: ${JSON.stringify(typeSelector.options)}`
      );
      cardFields = [];
    } else {
      cardFields = selectedTypeOption.fields;
    }
  }

  let typeHiddenInput;

  if (typeSelector) {
    typeHiddenInput = (
      <Controller
        as={'input'}
        type="hidden"
        name={`${nestedNamePrefix}.${typeSelector.typeFieldName}`}
        control={control}
        defaultValue={model[typeSelector.typeFieldName] || ''}
      />
    );
  }

  return (
    <React.Fragment>
      {typeHiddenInput}
      <Cmp
        nestedNamePrefix={nestedNamePrefix}
        control={control}
        typeSelector={typeSelector}
        {...rest}
        model={model}
        selectedTypeOption={selectedTypeOption}
        fields={cardFields}
      />
    </React.Fragment>
  );
};

const HorizontalCardListLayout = ({
  formFields,
  fields,
  cols,
  name,
  typeSelector,
  control,
  ...rest
}) => {
  const classes = useStyles();

  const hasItems = formFields && formFields.length;

  let mainContent;

  if (hasItems) {
    mainContent = (
      <GridList
        className={classes.gridList}
        cols={4}
        spacing={8}
        cellHeight={rest.cellHeight || 250}
      >
        {formFields.map((model, modelIndex) => {
          const nestedNamePrefix = `${name}[${modelIndex}]`;

          const cardProps = {
            ...rest,
            cols,
            fields,
            typeSelector,
            model,
            nestedNamePrefix,
            control,
            modelIndex,
            isLast: modelIndex === formFields.length - 1,
            direction: 'horizontal'
          };

          return (
            <GridListTile key={model._id}>
              <CardWrapper Cmp={OneColCard} {...cardProps} />
            </GridListTile>
          );
        })}
      </GridList>
    );
  } else {
    mainContent = (
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="center"
        padding={4}
        bgcolor="#f4f6f8"
      >
        <Typography>No items</Typography>
      </Box>
    );
  }

  return <div className={classes.root}>{mainContent}</div>;
};

const LAYOUT_TYPE_MAP = {
  verticalList: VerticalCardListLayout,
  horizontalList: HorizontalCardListLayout
};

const Layout = ({ layoutType, ...rest }) => {
  const Cmp = LAYOUT_TYPE_MAP[layoutType];

  return <Cmp {...rest} />;
};

const HasManyField = ({
  layout = 'verticalList',
  parentRecord,
  label,
  name,
  includeIds = true,
  cols = null,
  fields = null,
  cellHeight,
  typeSelector,
  collapsible,
  orderable = true
}) => {
  const classes = useStyles();

  const { control, setValue } = useFormContext();
  const [moveCount, setMoveCount] = useState(0);
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);

  const { fields: formFields, append, remove, move } = useFieldArray({
    name: name,
    keyName: '_id'
  });

  const onMove = (fromIndex, toIndex) => {
    move(fromIndex, toIndex);
    setMoveCount(moveCount + 1);
  };

  const handleTypeOptionSelected = option => {
    setSelectedTypeOption(option);
    append({
      [typeSelector.typeFieldName]: option.value
    });
  };

  const handleChange = event => {
    handleTypeOptionSelected(event.target.value);
  };

  const handleAddNewItem = () => {
    append({});
  };

  useEffect(() => {
    if (orderable) {
      formFields.forEach((formField, index) => {
        const nestedPositionName = `${name}[${index}].position`;
        setValue(nestedPositionName, index);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveCount]);

  let addButton;

  let finalFields;

  if (selectedTypeOption) {
    finalFields = selectedTypeOption.fields;
  } else {
    finalFields = fields;
  }

  if (typeSelector) {
    addButton = (
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="body2">{typeSelector.addLabel}</Typography>
        </Box>
        <Box ml={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select onChange={handleChange} value="Select a block">
              <MenuItem value="Select a block">Select a block</MenuItem>
              {typeSelector.options.map((option, index) => {
                return (
                  <MenuItem value={option} key={index}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <ButtonGroup color="primary" size="small">
            {typeSelector.options.map((option, index) => {
              return (
                <Button
                  onClick={() => handleTypeOptionSelected(option)}
                  key={index}
                >
                  {option.label}
                </Button>
              );
            })}
          </ButtonGroup> */}
        </Box>
      </Box>
    );
  } else {
    addButton = (
      <Button onClick={handleAddNewItem} variant="outlined">
        Add item
      </Button>
    );
  }

  return (
    <Box>
      {label && (
        <Box mb={1}>
          <InputLabel shrink>{label}</InputLabel>
        </Box>
      )}

      <Layout
        name={name}
        layoutType={layout}
        formFields={formFields}
        includeIds={includeIds}
        control={control}
        cols={cols}
        fields={finalFields}
        typeSelector={typeSelector}
        remove={remove}
        move={onMove}
        cellHeight={cellHeight}
        setValue={setValue}
        selectedTypeOption={selectedTypeOption}
        collapsible={collapsible}
        orderable={orderable}
      />

      <Divider />
      <Box mt={2}>{addButton}</Box>
    </Box>
  );
};

export default HasManyField;
