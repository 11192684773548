export default {
  label: 'Image',
  name: 'image_path',
  type: 'image',
  // initialUrlAttr: 'url',
  request: {
    endpoint: 'image-uploads',
    method: 'POST'
  },
  response: {
    previewUrlPath: 'url',
    valuePath: 'url'
  },
  width: 300,
  height: 300
};
