import React, { useEffect } from 'react';

import { TreeItem, TreeView } from '@material-ui/lab';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import { get } from 'lodash';

import useAction from 'src/hooks/useAction';
import { Checkbox, Box, InputLabel } from '@material-ui/core';
import TableLoader from '../../TableLoader';

const buildTree = (nodes, selectedOptions, onItemChecked) => {
  return (
    <React.Fragment>
      {nodes.map(node => {
        const childItems = node.children.length
          ? buildTree(node.children, selectedOptions, onItemChecked)
          : null;

        const optionValue = node.id;

        const checked = selectedOptions
          ? selectedOptions.includes(optionValue)
          : null;

        const handleChange = event => {
          onItemChecked(optionValue, event.target.checked);
        };

        const label = (
          <React.Fragment>
            <Checkbox
              onClick={e => e.stopPropagation()}
              checked={checked || false}
              onChange={handleChange}
            />
            {node.name}
          </React.Fragment>
        );

        return (
          <TreeItem key={optionValue} nodeId={`${optionValue}`} label={label}>
            {childItems}
          </TreeItem>
        );
      })}
    </React.Fragment>
  );
};

export default React.forwardRef(function TreeField(
  { value, onChange, actionParams, label, response },
  rest
) {
  const fetchOptions = useAction(actionParams);

  useEffect(() => {
    fetchOptions.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemChecked = nodeId => {
    const newValue = value ? [...value] : [];
    const existingIndex = newValue.indexOf(nodeId);

    if (existingIndex === -1) {
      newValue.push(nodeId);
    } else {
      newValue.splice(existingIndex, 1);
    }

    onChange(newValue);
  };

  const dataPath = get(response, 'dataPath');

  const fetchedOptions = fetchOptions.data
    ? dataPath
      ? get(fetchOptions.data, dataPath)
      : fetchOptions.data
    : fetchOptions.data;

  const mainContent = !fetchOptions.data ? (
    <TableLoader />
  ) : (
    <TreeView
      defaultExpanded={[]}
      multiSelect
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
    >
      {buildTree(fetchedOptions, value, onItemChecked)}
    </TreeView>
  );

  return (
    <Box>
      {label && (
        <Box mb={1}>
          <InputLabel shrink>{label}</InputLabel>
        </Box>
      )}
      <Box
        style={{ overflowY: 'auto', maxHeight: 300, background: '#fff' }}
        p={2}
        borderRadius={4}
        border={1}
        borderColor="grey.300"
      >
        {mainContent}
      </Box>
    </Box>
  );
});
