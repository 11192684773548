import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Actions from './Actions';

const DetailHeader = ({ title, breadcrumbs, actions }) => {
  const breadcrumbsLinks = breadcrumbs.map(({ to, label }, index) => (
    <Link
      key={index}
      variant="body1"
      color="inherit"
      to={to || '/'}
      component={RouterLink}
    >
      {label}
    </Link>
  ));

  return (
    <Box display="flex">
      <Box>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbsLinks}
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          {title}
        </Typography>
      </Box>
      {actions && (
        <Box display="flex" alignItems="center" ml="auto">
          <Box display="flex" alignItems="center">
            <Actions actions={actions} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DetailHeader;
