export default {
  value: 'text_wall',
  label: 'Text content',
  fields: [
    {
      label: 'Subtitle',
      name: 'subtitle'
    },
    {
      label: 'Content',
      name: 'content',
      type: 'richText'
    }
  ]
};
