export default forms => {
  return Promise.allSettled(
    forms.map(form => {
      return new Promise((resolve, reject) => {
        form.handleSubmit(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        )();
      });
    })
  ).then(results => {
    const validResults = results.filter(r => r.status === 'fulfilled');
    const errorResults = results.filter(r => r.status === 'rejected');

    return {
      allValid: validResults.length === results.length,
      data: validResults.map(res => res.value),
      errors: errorResults.map(res => res.reason)
    };
  });
};
