export const businessSelect = {
  label: 'Business',
  name: 'business',
  type: 'lazySelect',
  displayAttr: 'name',
  valueAttr: 'id',
  selectedOptionAttr: 'business',
  selectedWidget: {
    widget: 'card',
    main: 'name',
    image: {
      field: 'logo.file',
      width: 50,
      height: 50
    }
  },
  optionWidget: {
    widget: 'card',
    main: 'name',
    image: {
      field: 'logo.file',
      width: 50,
      height: 50
    }
  },
  request: {
    endpoint: 'businesses',
    params: {
      no_pagination: true,
      ordering: 'name',
      expand: 'logo'
    }
  }
};
