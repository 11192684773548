export const relatedProductPicker = {
  type: 'dataTable',
  url: 'products',
  tabs: [],
  selection: {
    multiple: false,
    valueSelector: 'id',
    selectedWidget: {
      widget: 'card',
      main: 'name',
      image: {
        field: 'mainImage.file',
        width: 50,
        height: 50
      }
    }
  },
  pagination: {
    mappers: {
      count: 'count'
    }
  },
  response: {
    mappers: {
      list: 'data'
    }
  },
  defaultParams: {
    expand: 'business__logo,main_image'
  },
  columns: [
    {
      label: 'Product',
      widget: 'card',
      main: 'name',
      image: {
        field: 'main_image.file',
        width: 50,
        height: 50
      }
    },
    {
      label: 'Aggiunto il',
      field: 'created',
      widget: 'datetime'
    },
    {
      label: 'Business',
      widget: 'card',
      main: 'business.name',
      image: {
        field: 'business.logo.file',
        width: 50,
        height: 50
      }
    }
  ]
};
