import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { get, toPairs } from 'lodash';
import ReactDependentScript from 'react-dependent-script';
import { useFormContext } from 'react-hook-form';

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}));

const parsePlace = res => {
  const point = {
    lat: res.geometry.location.lat(),
    lng: res.geometry.location.lng()
  };

  const streetNumber = parsePlaceComponent(
    res.address_components,
    'street_number'
  );

  const streetName = parsePlaceComponent(res.address_components, 'route');

  const city = parsePlaceComponent(
    res.address_components,
    'administrative_area_level_3'
  );

  const province = parsePlaceComponent(
    res.address_components,
    'administrative_area_level_2'
  );

  const region = parsePlaceComponent(
    res.address_components,
    'administrative_area_level_1'
  );
  const postcode = parsePlaceComponent(res.address_components, 'postal_code');

  return {
    longitude: point.lng,
    latitude: point.lat,
    streetNumber,
    streetName,
    city,
    region,
    postcode,
    province
  };
};

const parsePlaceComponent = (addressComponent, type) =>
  get(
    addressComponent.find(entry => entry.types.includes(type)),
    'short_name'
  );

const GooglePlaceField = React.forwardRef(function GooglePlaceField(
  { mapFields, onChange, ...fieldProps },
  ref
) {
  const classes = useStyles();
  const { setValue: setFormValue } = useFormContext();
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(selectedValue ? [selectedValue] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (selectedValue) {
          newOptions = [selectedValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [selectedValue, inputValue, fetch]);

  return (
    <ReactDependentScript
      loadingComponent={<div>Google maps is loading...</div>}
      scripts={[
        `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyBTK8W_F8XR-ivKaDrml147z6jQL0T8EB0'}&libraries=places`
      ]}
    >
      <Autocomplete
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={selectedValue}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setSelectedValue(newValue);

          if (newValue && placesService.current) {
            placesService.current.getDetails(
              { placeId: newValue.place_id },
              res => {
                const parsed = parsePlace(res);
                console.log('parsed', parsed);
                toPairs(mapFields).forEach(([placeEntry, toFieldName]) => {
                  setFormValue(toFieldName, parsed[placeEntry]);
                });
              }
            );
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={params => <TextField {...fieldProps} {...params} />}
        renderOption={option => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map(match => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => {
                  return (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  );
                })}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </ReactDependentScript>
  );
});

export default GooglePlaceField;
