import React from 'react';
import {
  TablePagination,
  Tabs,
  Tab,
  Divider,
  Card,
  CardContent
} from '@material-ui/core';

import useTableView from 'src/hooks/useTableView';

import ConfirmDeleteDialog from 'src/components/ConfirmDeleteDialog';
import Filters from 'src/generics/elements/Filters';

import Results from './Results';
import { DataTableContextProvider } from './DataTableContext';
import { useSnackbar } from 'notistack';
import BulkActionsBar from './BulkActionsBar';

const DataTable = ({
  config,
  resourceSlug,
  resourceId,
  pickerMode = false,
  onSelect = null,
  selectedKey = null
}) => {
  const {
    actions,
    pagination,
    onDelete,
    currentTab,
    resultToDelete,
    handleTabsChange,
    handleDeleteCancel,
    handleDeleteConfirm,
    appliedFilters,
    sortBy,
    sortByDir,
    handleToggleRowSelection,
    handleToggleSelectAll,
    handleBulkAction,
    selectedRows,
    bulkActionLoading,
    handleToggleSelectAllRecords,
    selectAllResults,
    handleRowAction,
    rowActionLoading
  } = useTableView({
    config,
    resourceId
  });

  const { enqueueSnackbar } = useSnackbar();

  const tabsConfig = config.tabs || [];

  const onDeleteConfirm = async (...args) => {
    await handleDeleteConfirm(...args);
    enqueueSnackbar('The record has been deleted', {
      variant: 'warning'
    });
  };

  return (
    <React.Fragment>
      {config.filters && (
        <Filters
          {...config.filters}
          onApplyFilters={actions.applyFilters}
          onResetFilters={actions.resetFilters}
        />
      )}

      {config.tabs && config.tabs.length ? (
        <React.Fragment>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={currentTab}
            variant="scrollable"
          >
            {tabsConfig.map(tab => (
              <Tab key={tab.id} value={tab.id} label={tab.label} />
            ))}
          </Tabs>
          <Divider />
        </React.Fragment>
      ) : null}

      <Card>
        <CardContent>
          <DataTableContextProvider
            data={{
              resourceSlug,
              pagination,
              config,
              loading: actions.fetch.loading,
              columns: config.columns,
              bulkActions: config.bulkActions,
              results: actions.fetch.results,
              onApplyFilters: actions.applyFilters,
              appliedFilters: appliedFilters,
              sortBy,
              sortByDir,
              onApplySortBy: actions.applySortBy,
              onDelete,
              pickerMode,
              onSelect,
              selectedKey,
              maxHeight: config.maxHeight,
              handleToggleRowSelection,
              handleToggleSelectAll,
              selectedRows,
              handleBulkAction,
              bulkActionLoading,
              handleToggleSelectAllRecords,
              selectAllResults,
              handleRowAction,
              rowActionLoading
            }}
          >
            <BulkActionsBar />

            <Results />

            {pagination &&
            !actions.fetch.loading &&
            actions.fetch.results.length ? (
              <TablePagination
                component="div"
                count={pagination.count}
                onChangePage={pagination.onChangePage}
                page={pagination.page}
                rowsPerPage={pagination.limit}
                rowsPerPageOptions={[]}
              />
            ) : null}
          </DataTableContextProvider>
        </CardContent>
      </Card>

      {!pickerMode && resultToDelete && (
        <ConfirmDeleteDialog
          onConfirm={onDeleteConfirm}
          onCancel={handleDeleteCancel}
          message={`Are you sure you want to delete this record?`}
        />
      )}
    </React.Fragment>
  );
};

export default DataTable;
