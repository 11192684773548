import { createFieldset } from './fieldset';

export default {
  title: 'Crea account Cliente',
  elements: [
    {
      type: 'createForm',
      config: {
        actions: {
          save: {
            endpoint: 'customers',
            method: 'POST'
          }
        },
        fieldsets: [createFieldset]
      }
    }
  ]
};
