export const supplierListFilters = {
  fields: [
    {
      label: 'Search',
      name: 'filter[screen_name]',
      size: 'small',
      span: 4,
      bgColor: 'white'
    }
  ]
};

export const supplierListCard = {
  widget: 'card',
  main: 'supplier.screen_name',
  image: {
    field: 'supplier.logo_url',
    width: 40,
    height: 40
  }
};

export const supplierSelect = {
  name: 'supplier_id',
  type: 'lazySelect',
  displayAttr: 'screen_name',
  selectedOptionAttr: 'supplier',
  searchable: true,
  request: {
    endpoint: 'suppliers',
    params: { sort: 'screen_name', nopagination: true },
    dataPath: 'data'
  },
  selectedWidget: {
    widget: 'card',
    main: 'screen_name',
    image: {
      field: 'logo_url',
      width: 40,
      height: 40
    }
  },
  optionWidget: {
    widget: 'card',
    main: 'screen_name',
    image: {
      field: 'logo_url',
      width: 30,
      height: 30
    }
  }
};
