import fieldset from './fieldset';

export default {
  title: 'Create Product',

  elements: [
    {
      type: 'createForm',
      config: {
        actions: {
          save: {
            endpoint: 'products',
            method: 'POST'
          }
        },
        fieldsets: [fieldset]
      }
    }
  ]
};
