/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';

import {
  PieChart,
  Users,
  ShoppingBag,
  ShoppingCart,
  Percent,
  Repeat,
  MapPin,
  Key,
  Archive,
  MessageSquare
} from 'react-feather';

import { StorefrontOutlined, ClassOutlined } from '@material-ui/icons';

import Logo from 'src/components/Logo';
import { getAppConfig } from 'src/generics/dataUtils';

import NavItem from './NavItem';

const ICONS = {
  PieChart,
  Users,
  ShoppingBag,
  ShoppingCart,
  Percent,
  Repeat,
  MapPin,
  Key,
  StorefrontOutlined,
  Archive,
  MessageSquare,
  ClassOutlined,
  Membership: CardMembershipOutlinedIcon,
  Page: DescriptionOutlinedIcon,
  Cart: ShoppingCartOutlinedIcon
};

function renderNavItems({ items, pathname, depth = 0, appName }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, appName }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, appName }) {
  const key = item.title + depth;
  const IconCmp = ICONS[item.icon];

  const href = `/${appName}${item.href}`;

  if (item.items) {
    const open = matchPath(pathname, {
      path: href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={IconCmp}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          appName
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={href}
        icon={IconCmp}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const { appName } = useParams();

  const classes = useStyles();
  const location = useLocation();

  const appConfig = getAppConfig(appName);

  const { sideMenu } = appConfig;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          {sideMenu.items.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                appName
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <React.Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};
export default NavBar;
