import * as fieldset from './fieldset';
import configs from '../../configs';

export default {
  title: 'Edit Supplier',
  elements: [
    {
      title: 'Main',
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'suppliers/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'suppliers/{id}',
            method: 'PUT'
          }
        },
        fieldsets: [fieldset.main],
        versionFormView: {
          type: 'editForm',
          versions: configs.versions,
          config: {
            actions: {
              fetch: {
                endpoint: 'suppliers/{id}',
                method: 'GET',
                dataPath: 'data',
                params: {
                  lang: '{versionId}'
                }
              },
              save: {
                endpoint: 'suppliers/{id}',
                method: 'PUT',
                params: {
                  lang: '{versionId}'
                }
              }
            },
            fieldsets: [
              {
                sections: [
                  {
                    fields: [
                      {
                        label: 'Description',
                        name: 'company_description',
                        type: 'richText'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      }
    }
    // {
    //   title: 'Account',
    //   type: 'editForm',
    //   config: {
    //     actions: {
    //       fetch: {
    //         endpoint: 'suppliers/{id}',
    //         method: 'GET',
    //         dataPath: 'data'
    //       },
    //       save: {
    //         endpoint: 'suppliers/{id}/reset-password',
    //         method: 'POST'
    //       }
    //     },
    //     fieldsets: [fieldset.account]
    //   }
    // }
  ]
};
