import React from 'react';
import ListPage from 'src/components/pages/ListPage';

import ViewElements from '../ViewElements';

const TreeViewPage = ({ config, resourceSlug }) => {
  return (
    <ListPage title={config.title} breadcrumbs={[]} actions={[]}>
      <ViewElements resourceSlug={resourceSlug} elements={config.elements} />
    </ListPage>
  );
};

export default TreeViewPage;
