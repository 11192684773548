import React, { createContext } from 'react';

const DataContext = createContext(null);

export const DataContextProvider = ({ children, data }) => {
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export const DataContextConsumer = DataContext.Consumer;

export default DataContext;
