import React, { useRef, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';

import { mapValues, has } from 'lodash';
import { useSnackbar } from 'notistack';
import expandString from 'expand-template';

import useAction from 'src/hooks/useAction';

import Form from 'src/components/forms/Form';
import FormContent from '../FormContent/index';

const stringExpander = expandString();

const CreateForm = ({ config, onSaved }) => {
  const { enqueueSnackbar } = useSnackbar();

  const actions = {
    save: useAction({
      url: stringExpander(config.actions.save.endpoint, {}),
      method: config.actions.save.method
    })
  };

  const forms = {
    main: useRef()
  };

  const onSave = event => {
    event.preventDefault();

    forms.main.current.handleSubmit(
      async data => {
        const finalData = mapValues(data, val =>
          val && has(val, '$deleted') ? null : val
        );

        console.log('data to send', finalData);

        try {
          await actions.save.execute({
            data: finalData
          });
        } catch (error) {
          if (!error.response) {
            throw error;
          }

          const { status } = error.response;

          if (status === 500) {
            enqueueSnackbar(
              'Oops, si è verificato un errore inaspettato. Il problema è stato segnalato automaticamente.',
              {
                variant: 'error'
              }
            );
            return;
          }

          if (status === 422) {
            enqueueSnackbar('Errore di validazione lato server.', {
              variant: 'error'
            });
            return;
          }
        }

        enqueueSnackbar('Record creato.', {
          variant: 'success'
        });
      },
      errors => {
        console.log('Form validation errors', errors);

        enqueueSnackbar(
          'Please fix any validation errors displayed and try again.',
          {
            variant: 'error'
          }
        );
      }
    )();
  };

  useEffect(() => {
    if (onSaved) {
      onSaved(actions.save.data);
    }
  }, [actions.save.data]);

  const fieldset = config.fieldsets[0];
  const content = (
    <FormContent fieldset={fieldset} initialValues={fieldset.initialValues} />
  );

  return (
    <form onSubmit={onSave}>
      <Form
        ref={forms.main}
        schema={fieldset.schema}
        initialValues={fieldset.initialValues}
        fieldset={content}
      />
      <Box mt={3}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={actions.save.loading}
        >
          Crea record
        </Button>
      </Box>
    </form>
  );
};

export default CreateForm;
