import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  ButtonGroup
} from '@material-ui/core';

import { mapValues, get } from 'lodash';
import { useSnackbar } from 'notistack';
import expandString from 'expand-template';

import useAction from 'src/hooks/useAction';

import Form from 'src/components/forms/Form';
import { extract } from 'src/dataUtils';
import TableLoader from 'src/components/TableLoader';
import FormContent from '../FormContent/index';
import useData from 'src/hooks/useData';
import { parsePaths } from 'src/dataUtils';
import ViewElements from '../../ViewElements';
import { expandParams } from '../../../dataUtils';
import { DataContextProvider } from 'src/contexts/DataContext';

const stringExpander = expandString();

const normaliseFormInitialValues = (config, values) => {
  const transform = get(config, 'actions.fetch.transform');
  if (!transform) {
    return values;
  }
  return transform(values);
};

const VersionModal = ({
  config,
  onClose,
  open,
  resourceId,
  resourceSlug,
  editVersion,
  maxWidth = 'md'
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      onSubmit={e => e.stopPropagation()}
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle onClose={onClose}>
        Modifica versione: {editVersion.label}
      </DialogTitle>
      <DialogContent dividers>
        <DataContextProvider data={{ versionId: editVersion.id }}>
          <ViewElements
            resourceSlug={resourceSlug}
            resourceId={resourceId}
            elements={[config.versionFormView]}
          />
        </DataContextProvider>
      </DialogContent>
    </Dialog>
  );
};

const EditForm = ({ config, resourceId, resourceSlug, onSaved }) => {
  const { enqueueSnackbar } = useSnackbar();
  const contextData = useData();

  const [editVersion, setEditVersion] = useState(null);

  const handleVersionModalClose = () => {
    setEditVersion(null);
  };

  const shouldFetchData = !!config.actions.fetch;

  const urlContext = config.actionContext
    ? parsePaths(config.actionContext, contextData || {})
    : {
        id: resourceId
      };

  const actionParams = {
    fetch: shouldFetchData
      ? {
          url: stringExpander(config.actions.fetch.endpoint, urlContext),
          method: config.actions.fetch.method,
          params: config.actions.fetch.params
            ? expandParams(config.actions.fetch.params, contextData || {})
            : {}
        }
      : {}
  };

  const actions = {
    fetch: useAction(actionParams.fetch),
    save: useAction({
      url: stringExpander(config.actions.save.endpoint, urlContext),
      method: config.actions.save.method,
      params: config.actions.save.params
        ? expandParams(config.actions.save.params, contextData || {})
        : {}
    })
  };

  useEffect(() => {
    if (shouldFetchData) {
      actions.fetch.execute();
    }
    // FIXME: needed cause actions are recreated!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailsData = shouldFetchData
    ? actions.fetch.data
      ? config.actions.fetch.dataPath
        ? extract(actions.fetch.data, config.actions.fetch.dataPath)
        : actions.fetch.data
      : null
    : contextData;

  const forms = {
    main: useRef()
  };

  const onSave = event => {
    event.preventDefault();

    forms.main.current.handleSubmit(
      async data => {
        const finalData = mapValues(data, val =>
          val && (get(val, '$deleted') || get(val, '$new')) ? null : val
        );

        console.log('data to send', finalData);

        await actions.save.execute({
          data: finalData
        });

        if (shouldFetchData) {
          actions.fetch.execute();
        }

        enqueueSnackbar('Modifiche salvate.', {
          variant: 'success'
        });
      },
      errors => {
        console.log('Form validation errors', errors);

        enqueueSnackbar(
          'Please fix any validation errors displayed and try again.',
          {
            variant: 'error'
          }
        );
      }
    )();
  };

  useEffect(() => {
    if (onSaved) {
      onSaved(actions.save.data);
    }
  }, [actions.save.data]);

  const fieldset = config.fieldsets[0];
  let initialValues = fieldset.initialValues;
  let content;

  if (detailsData) {
    initialValues = normaliseFormInitialValues(
      config,
      fieldset.contextDataPrefix
        ? get(detailsData, fieldset.contextDataPrefix)
        : detailsData,
      fieldset.schema
    );

    content = <FormContent fieldset={fieldset} initialValues={initialValues} />;
  } else {
    content = <FormContent fieldset={fieldset} initialValues={initialValues} />;
  }

  if (actions.fetch.loading) {
    return <TableLoader />;
  }

  if (!initialValues) {
    return null;
  }

  return (
    <form onSubmit={onSave}>
      {config.versionFormView && (
        <Box mb={2}>
          <Box mb={2} display="flex" alignItems="center">
            <Box mr={2}>
              <Typography variant="body2">Traduzioni:</Typography>
            </Box>
            <Box>
              <ButtonGroup color="primary" size="small">
                {config.versionFormView.versions.map((version, index) => {
                  return (
                    <Button onClick={() => setEditVersion(version)} key={index}>
                      {version.label}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
          </Box>
          <Divider />
        </Box>
      )}

      <Form
        ref={forms.main}
        schema={fieldset.schema}
        initialValues={initialValues}
        fieldset={content}
      />
      <Box mt={3} pl={2}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={actions.save.loading}
        >
          Salva modifiche
        </Button>
      </Box>

      {config.versionFormView && editVersion && (
        <VersionModal
          editVersion={editVersion}
          onClose={handleVersionModalClose}
          open={true}
          config={config}
          resourceId={resourceId}
          resourceSlug={resourceSlug}
          maxWidth={config.versionFormView.maxWidth}
        />
      )}
    </form>
  );
};

export default EditForm;
