import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { appName } = useParams();

  if (!isAuthenticated) {
    return <Redirect to={`/${appName}/login`} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
