import {
  card,
  column,
  identifierLabel,
  path,
  logo,
  dateTime,
  pagination,
  thumb
} from 'src/data/types/defs';
import { supplierSelect } from '../supplier/shared';

export const productListFilters = {
  fields: [
    {
      label: 'Search',
      name: 'filter[name]',
      size: 'small',
      span: 4,
      bgColor: 'white'
    },
    {
      span: 4,
      label: 'Categories',
      name: 'filter[category_id]',
      type: 'treeSelect',
      size: 'small',
      response: {
        dataPath: 'data'
      },
      actionParams: {
        url: 'categories',
        params: {
          mode: 'tree'
        }
      }
    },
    {
      span: 4,
      label: 'Supplier',
      name: 'filter[supplier_id]',
      type: 'lazySelect',
      inlineLabel: true,
      displayAttr: 'screen_name',
      selectedOptionAttr: 'supplier',
      size: 'small',
      request: {
        endpoint: 'suppliers',
        params: { sort: 'screen_name', nopagination: true },
        dataPath: 'data'
      },
      selectedWidget: {
        widget: 'card',
        main: 'screen_name',
        image: {
          field: 'logo_url',
          width: 20,
          height: 20
        }
      },
      optionWidget: {
        widget: 'card',
        main: 'screen_name',
        image: {
          field: 'logo_url',
          width: 20,
          height: 20
        }
      }
    }
  ]
};

export const productTable = {
  type: 'dataTable',
  config: {
    filters: productListFilters,
    url: 'products',
    defaultParams: {
      sort: '-created_at'
    },
    response: {
      mappers: {
        list: 'data'
      }
    },
    bulkActions: [
      {
        label: 'Delete',
        request: {
          method: 'POST',
          endpoint: 'products/bulk-actions/create'
        }
      },
      {
        label: 'Mass edit fields...',
        form: {
          fields: [
            {
              label: 'Published',
              name: 'published',
              type: 'switch'
            },
            {
              ...supplierSelect,
              span: 4
            },
            {
              label: 'Select categories to assign',
              name: 'category_ids',
              type: 'tree',
              actionParams: {
                url: 'categories',
                params: {
                  mode: 'tree'
                }
              },
              response: {
                dataPath: 'data'
              }
            }
          ]
        },
        request: {
          method: 'POST',
          endpoint: 'products/bulk-actions/mass-edit'
        }
      }
    ],
    tabs: [
      {
        id: 'published',
        label: 'Pubblicati',
        requestParams: {
          'filter[published]': 1
        }
      },
      {
        id: 'unpublished',
        label: 'Non pubblicati',
        requestParams: {
          'filter[published]': 0
        }
      }
    ],
    pagination: pagination({
      mappers: {
        count: 'meta.total',
        perPage: 'meta.per_page'
      }
    }),
    columns: [
      column({
        label: 'Name',
        sortable: true,
        widget: card({
          main: 'name',
          image: thumb({
            field: 'listing_image_url',
            width: 100,
            height: 100
          }),
          secondary: identifierLabel({
            field: 'sku'
          }),
          tertiary: path({
            field: 'main_category_tree',
            itemNamePath: 'name'
          })
        })
      }),
      // column({
      //   label: 'Published',
      //   field: 'published',
      //   align: 'center',
      //   widget: 'publishedIcon'
      // }),
      column({
        label: 'Supplier',
        align: 'center',
        widget: logo({
          field: 'supplier.logo_url'
        })
      }),
      column({
        label: 'Created',
        sortable: true,
        widget: dateTime({
          field: 'created_at'
        })
      })
    ]
  }
};
