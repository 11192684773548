import React, { createContext, useEffect, useReducer } from 'react';
// import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import useApiClient from 'src/hooks/useApiClient';
import { getAppConfig } from 'src/generics/dataUtils';

import { extract } from 'src/dataUtils';
import { useParams } from 'react-router-dom';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  return true;

  // const decoded = jwtDecode(accessToken);
  // const currentTime = Date.now() / 1000;

  // return decoded.exp > currentTime;
};

const setSession = (accessToken, client, authConfig) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    client.defaults.headers.common.Authorization = `${authConfig.tokenHeaderName} ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete client.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => {
    return Promise.resolve();
  },
  logout: () => {},
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { client } = useApiClient();

  const { appName } = useParams();

  const { auth } = getAppConfig(appName);

  const login = async (email, password) => {
    const response = await client.post(
      auth.login.path,
      {
        email,
        password
      },
      {
        headers: {
          Authorization: undefined
        }
      }
    );

    const authToken = extract(response.data, auth.login.response.mappers.token);

    const user = extract(response.data, auth.login.response.mappers.user);

    setSession(authToken, client, auth);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null, client, auth);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, client, auth);

          const response = await client.get(auth.userProfile.path);
          const user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, [client]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
