// import { productTable } from '../product/shared';

export default {
  title: 'Dettaglio Tipologia Bowl',
  actions: [
    {
      label: 'Modifica',
      type: 'edit'
    },
    {
      label: 'Elimina',
      type: 'delete'
    }
  ],
  elements: [
    {
      type: 'detailsPanel',
      config: {
        actions: {
          fetch: {
            endpoint: 'bowl-types/{id}',
            method: 'GET'
          }
        },
        sections: [
          {
            headerWidget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'name',
                variant: 'h4'
              },
              image: {
                label: 'Codice',
                widget: 'qrCode',
                field: 'qrcodeValue'
              },
              secondary: {
                widget: 'text',
                field: 'codename'
              }
            },
            fields: []
          }
        ]
      }
    }
  ]
};
