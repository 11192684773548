import React from 'react';
import { getValue } from 'src/dataUtils';

import { Avatar } from '@material-ui/core';

const Logo = ({ data, field, width, height }) => {
  const value = getValue(data, field);

  return <Avatar alt="" src={value} style={{ width, height }} />;
};

export default Logo;
