import React, { useState, useRef } from 'react';
import { pickBy } from 'lodash';
import {
  Box,
  Typography,
  Paper,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import useDataTable from './useDataTable';
import Form from 'src/components/forms/Form';
import FormField from 'src/components/forms/FormField';
import { useSnackbar } from 'notistack';

const useToolbarStyles = makeStyles(() => ({
  root: {
    // color: theme.palette.secondary.main,
    // backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  }
}));

const ActionButton = ({ action }) => {
  const { handleBulkAction, bulkActionLoading } = useDataTable();
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [enabledFields, setEnabledFields] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleEnableFields = field => {
    const newSelection = [...enabledFields];
    const existingIndex = newSelection.indexOf(field.name);

    if (existingIndex > -1) {
      newSelection.splice(existingIndex, 1);
    } else {
      newSelection.push(field.name);
    }

    setEnabledFields(newSelection);
  };

  const handleAction = () => {
    if (action.form) {
      setFormDialogOpen(true);
    }
  };

  const onSave = event => {
    event.preventDefault();

    formRef.current.handleSubmit(
      async data => {
        const dataForEnabledFields = pickBy(data, (value, fieldName) =>
          enabledFields.includes(fieldName)
        );

        await handleBulkAction(action, { data: dataForEnabledFields });

        enqueueSnackbar('Bulk acttion applied', {
          variant: 'success'
        });

        setFormDialogOpen(false);
      },
      errors => {
        console.log('Form validation errors', errors);
      }
    )();
  };

  const formRef = useRef();

  return (
    <React.Fragment>
      <Button variant="outlined" size="small" onClick={handleAction}>
        {action.label}
      </Button>

      {action.form && formDialogOpen && (
        <Dialog
          open
          onClose={() => setFormDialogOpen(false)}
          maxWidth={'md'}
          fullWidth
        >
          <DialogTitle>{action.label}</DialogTitle>
          <DialogContent>
            <form onSubmit={onSave}>
              <Form
                ref={formRef}
                schema={action.form.schema}
                initialValues={action.form.initialValues}
                fieldset={
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Set</TableCell>
                        <TableCell>Field</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {action.form.fields.map((field, index) => {
                        const fieldEnabled = enabledFields.includes(field.name);

                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Checkbox
                                checked={fieldEnabled}
                                onChange={() => handleEnableFields(field)}
                              />
                            </TableCell>
                            <TableCell>
                              <FormField {...field} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                }
              />
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={bulkActionLoading}
                >
                  {bulkActionLoading ? 'Working...' : 'Apply bulk action'}
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

const BulkActionsBar = () => {
  const classes = useToolbarStyles();
  const {
    selectedRows = [],
    bulkActions = [],
    pagination,
    handleToggleSelectAllRecords,
    selectAllResults
  } = useDataTable();

  if (!selectedRows.length || !bulkActions || !bulkActions.length) {
    return null;
  }

  const actionButtons = bulkActions.map((action, index) => {
    return (
      <Box key={index} mr={1}>
        <ActionButton action={action} />
      </Box>
    );
  });

  return (
    <React.Fragment>
      <Box
        p={2}
        component={Paper}
        classes={{ root: classes.root }}
        elevation={0}
        display="flex"
        alignItems="center"
        onSubmit={e => e.stopPropagation()}
      >
        <Box mr={2}>
          <Box display="flex" alignItems="center">
            <Box>
              {selectAllResults ? (
                <Typography color="textSecondary">
                  All {pagination.count} results selected.
                </Typography>
              ) : (
                <Typography color="textSecondary">
                  {selectedRows.length} row{selectedRows.length > 1 ? 's' : ''}{' '}
                  selected.
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center" ml={2}>
              {pagination && !selectAllResults && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => handleToggleSelectAllRecords(true)}
                >
                  Select all {pagination.count} results
                </Button>
              )}
              {selectAllResults && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => handleToggleSelectAllRecords(false)}
                >
                  Deselect all
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {actionButtons}
        </Box>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

export default BulkActionsBar;
