export default {
  items: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: 'PieChart',
          href: '/app/reports/dashboard'
        }
      ]
    },
    {
      subheader: 'Inventario',
      items: [
        {
          title: 'Bowl',
          icon: 'Archive',
          href: '/app/bowl/list',
          items: [
            {
              title: 'Tipologie Bowl',
              href: '/app/bowltype/list'
            }
          ]
        }
      ]
    },
    {
      subheader: 'Transazioni',
      items: [
        {
          title: 'Prestiti e Restituzioni',
          icon: 'Archive',
          href: '/app/bowl/list',
          items: [
            {
              title: 'Elenco transazioni',
              href: '/app/bowltransaction/list'
            }
          ]
        }
      ]
    },
    {
      subheader: 'E-Commerce',
      items: [
        {
          title: 'Prodotti',
          icon: 'Archive',
          href: '/app/product/list',
          items: [
            {
              title: 'Elenco prodotti',
              href: '/app/product/list'
            }
          ]
        },
        {
          title: 'Offerte',
          icon: 'Archive',
          href: '/app/offer/list',
          items: [
            {
              title: 'Elenco offerte',
              href: '/app/offer/list'
            }
          ]
        }
      ]
    },
    {
      subheader: 'Account',
      items: [
        {
          title: 'Business',
          icon: 'Archive',
          href: '/app/business/list'
        },
        {
          title: 'Clienti',
          icon: 'Archive',
          href: '/app/customer/list'
        }
      ]
    }
  ]
};
