import React from 'react';

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';

export default React.forwardRef(function RadioGroupField(
  { name, label, value, onChange, options = [], row },
  ref
) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        row={row}
        name={name}
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});
