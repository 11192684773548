import React, { Suspense, lazy } from 'react';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import View from './generics/View';
import LoginView from './views/auth/LoginView/index';

const routes = [
  // {
  //   path: '',
  //   exact: true,
  //   component: () => <Redirect to="/app/product/listEEE" />
  // },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/:resourceSlug/:viewType/:resourceId?',
        component: lazy(() => import('src/generics/View'))
      },
      {
        exact: true,
        path: '',
        component: () => <Redirect to="/app/product/listaaaaa" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export const AppRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route
          exact
          path={`${path}/404`}
          component={lazy(() => import('src/views/errors/NotFoundView'))}
        />
        <Route
          exact
          path={`${path}`}
          render={({ match }) => (
            <Redirect to={`${match.params.appName}/login`} />
          )}
        />
        <Route
          exact
          path={`${path}/login`}
          render={() => (
            <GuestGuard>
              <LoginView />
            </GuestGuard>
          )}
        />
        <Route
          path={`${path}/app/:resourceSlug/:viewType/:resourceId?`}
          render={props => (
            <AuthGuard>
              <DashboardLayout>
                <View {...props} />
              </DashboardLayout>
            </AuthGuard>
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default routes;
