import * as yup from 'yup';
import { relatedProductPicker } from '../product/pickers';

export default {
  title: 'Main info',
  initialValues: {
    name: '',
    featured_items: []
  },
  schema: yup.object().shape({
    name: yup.string(),
    description: yup.string(),
    parent_id: yup.string().nullable(),
    cover_image: yup
      .object()
      .shape({
        title: yup.string().nullable(),
        path: yup.string()
      })
      .nullable(),
    featured_items: yup.array().of(
      yup.object().shape({
        product_id: yup.mixed().notOneOf([undefined, null, ''])
      })
    )
  }),
  sections: [
    {
      title: 'Main details',
      fields: [
        // {
        //   label: 'Tree',
        //   name: 'items',
        //   type: 'sortableTree',
        //   form: {
        //     sections: [
        //       {
        //         fields: [
        //           {
        //             label: 'Title',
        //             name: 'title',
        //             size: 'small'
        //           }
        //         ]
        //       }
        //     ]
        //   }
        // },
        {
          label: 'Name',
          name: 'name'
        },
        {
          label: 'Parent',
          name: 'parent_id',
          type: 'treeSelect',
          selectedOptionAttr: 'parent',
          labelAttr: 'name',
          clearableLable: 'Set as top level',
          emptyValueLabel: 'Top level',
          response: {
            dataPath: 'data'
          },
          actionParams: {
            url: 'categories',
            params: {
              mode: 'tree'
            }
          }
        },
        {
          label: 'Description',
          name: 'description',
          type: 'richText'
        }
      ]
    },
    {
      title: 'Images',
      fields: [
        {
          label: 'Cover image',
          name: 'cover_image',
          type: 'hasOne',
          fields: [
            {
              label: 'Image',
              name: 'path',
              type: 'image',
              initialUrlAttr: 'url',
              request: {
                endpoint: 'image-uploads',
                method: 'POST'
              },
              response: {
                previewUrlPath: 'url',
                valuePath: 'path'
              },
              width: 500,
              height: 200
            }
          ]
        }
      ]
    },
    {
      title: 'Featured items',
      fields: [
        {
          label: 'Items',
          name: 'featured_items',
          type: 'hasMany',
          includeIds: true,
          layout: 'horizontalList',

          fields: [
            {
              type: 'relatedPicker',
              label: 'Featured product',
              name: 'product_id',
              initialValuePath: 'product',
              title: 'Select a product',
              viewConfig: relatedProductPicker
            }
          ]
        }
      ]
    },
    {
      title: 'Main navigation',
      fields: [
        {
          label: 'Show in the main menu',
          name: 'show_in_menu',
          type: 'switch'
        },
        {
          label: 'Override menu item title',
          name: 'menu_item_title'
        }
      ]
    }
  ]
};
