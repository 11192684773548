import React from 'react';
import { Typography } from '@material-ui/core';
import { isPlainObject } from 'lodash';

import { getValue } from 'src/dataUtils';

const Text = ({ data, field, variant = 'body1' }) => {
  const value = getValue(data, field);

  if (isPlainObject(value)) {
    console.error('Trying to render an object', value);
    return null;
  }

  return (
    <Typography variant={variant} gutterBottom>
      {value}
    </Typography>
  );
};

export default Text;
