import React, { useState, useEffect } from 'react';
import useAction from 'src/hooks/useAction';
import { get } from 'lodash';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Image as ImageIcon } from '@material-ui/icons';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel
} from '@material-ui/core';

const ImageField = React.forwardRef(
  (
    {
      name,
      label,
      btnLabel = 'Upload',
      parentRecord = null,
      initialUrlAttr = null,
      onChange,
      error = false,
      helperText = '',
      width = 100,
      height = 100,
      value,
      request,
      response
    },
    ref
  ) => {
    const [newValue, setNewValue] = useState(null);
    const [cleared, setCleared] = useState(false);

    const uploadImageAction = useAction({
      url: request.endpoint,
      method: request.method
    });

    const handleFileUpload = e => {
      const files = Array.from(e.target.files);
      const formData = new FormData();

      formData.append(request.fileFieldName || 'file', files[0]);

      uploadImageAction.execute({
        data: formData
      });
    };

    useEffect(() => {
      if (uploadImageAction.data) {
        const previewUrl = get(uploadImageAction.data, response.previewUrlPath);
        const val = get(uploadImageAction.data, response.valuePath);
        setNewValue(previewUrl);
        onChange(val);
      }
    }, [uploadImageAction.data, onChange, setNewValue]);

    useEffect(() => {
      if (value === '_deleted_') {
        setCleared(true);
        setNewValue(null);
      }
    }, [value]);

    const initialUrl = get(parentRecord, initialUrlAttr) || value;

    const currentVal = cleared ? newValue : newValue || initialUrl;

    const id = name;

    return (
      <FormControl variant="outlined" fullWidth error={!!error}>
        <InputLabel shrink>{label}</InputLabel>
        <Box display="flex" flexDirection="column" mt={2}>
          <Box mb={2}>
            {currentVal ? (
              <Box
                width={width}
                height={height}
                borderRadius={4}
                border={1}
                borderColor={'grey.400'}
                style={{
                  backgroundImage: `url(${currentVal})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover'
                }}
              />
            ) : (
              <Box
                display="flex"
                width={width}
                height={height}
                alignItems="center"
                justifyContent="center"
                bgcolor="grey.200"
                borderRadius="borderRadius"
                border={error ? 1 : 0}
                borderColor={error ? 'error.main' : undefined}
              >
                {uploadImageAction.loading ? (
                  <CircularProgress />
                ) : (
                  <ImageIcon fontSize="large" />
                )}
              </Box>
            )}
          </Box>

          <Box>
            {helperText && (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color={error ? 'error' : undefined}
              >
                {helperText}
              </Typography>
            )}
            <Box>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                type="file"
                id={id}
                onChange={handleFileUpload}
              />
              <label htmlFor={id}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  {currentVal ? 'Change' : btnLabel}
                </Button>
              </label>
            </Box>
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export default ImageField;
