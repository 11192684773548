import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { getAppConfig } from 'src/generics/dataUtils';

const GuestGuard = ({ children }) => {
  const { appName } = useParams();
  const { isAuthenticated } = useAuth();

  const { auth } = getAppConfig(appName);

  if (isAuthenticated) {
    return <Redirect to={`/${appName}${auth.login.redirectTo}`} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
