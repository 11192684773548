import React from 'react';

import DataTable from 'src/generics/elements/DataTable';
import TreeTable from 'src/generics/elements/TreeTable';
import CreateForm from 'src/generics/elements/CreateForm';
import EditForm from 'src/generics/elements/EditForm';
import DetailsPanel from 'src/generics/elements/DetailsPanel';
import { Box, Typography, Divider } from '@material-ui/core';

const ELEMENT_MAP = {
  dataTable: DataTable,
  treeTable: TreeTable,
  createForm: CreateForm,
  editForm: EditForm,
  detailsPanel: DetailsPanel
};

const getElementComponent = element => {
  return ELEMENT_MAP[element.type];
};

const ViewElements = ({ elements, resourceSlug, resourceId, ...rest }) => {
  const renderedElements = elements.map((elem, index) => {
    const ElemCmp = getElementComponent(elem);
    return (
      <Box key={index} mb={6}>
        {elem.title && (
          <Box mb={3}>
            <Box mb={1} px={2}>
              <Typography variant="h4">{elem.title}</Typography>
            </Box>
            <Divider />
          </Box>
        )}

        <ElemCmp
          resourceSlug={resourceSlug}
          resourceId={resourceId}
          config={elem.config}
          {...rest}
        />
      </Box>
    );
  });

  return <React.Fragment>{renderedElements}</React.Fragment>;
};

export default ViewElements;
