import ctaField from '../fields/ctaField';
import blockBgColorField from '../fields/blockBgColorField';

export default {
  value: 'text_carousel',
  label: 'Text carousel',
  fields: [
    blockBgColorField,
    {
      label: 'Pretitle',
      name: 'pretitle'
    },
    {
      label: 'Title',
      name: 'title'
    },
    {
      name: 'items',
      type: 'hasMany',
      includeIds: false,
      collapsible: false,
      layout: 'verticalList',
      fields: [
        {
          label: 'Content',
          name: 'content',
          type: 'richText'
        },
        {
          name: 'caption',
          label: 'Caption'
        }
      ]
    },
    ctaField
  ]
};
