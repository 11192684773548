import React from 'react';
import PublishedIcon from './PublishedIcon';
import IdentifierLabel from './IdentifierLabel';
import Thumb from './Thumb';
import Datetime from './Datetime';
import Logo from './Logo';
import Card from './Card';
import Text from './Text';
import HtmlContent from './HtmlContent';
import Path from './Path';
import QRCode from './QRCode';
import Url from './Url';
import Check from './Check';
import MonetaryAmount from './MonetaryAmount';

const WIDGET_MAP = {
  card: Card,
  logo: Logo,
  publishedIcon: PublishedIcon,
  identifierLabel: IdentifierLabel,
  thumb: Thumb,
  datetime: Datetime,
  text: Text,
  htmlContent: HtmlContent,
  path: Path,
  qrCode: QRCode,
  url: Url,
  check: Check,
  monetaryAmount: MonetaryAmount
};

const Widget = ({ attrs, data }) => {
  if (!attrs.widget) {
    return <Text data={data} {...attrs} />;
  }

  const Cmp = WIDGET_MAP[attrs.widget];

  if (!Cmp) {
    console.error(`Invalid Widget type: ${attrs.widget}`, attrs);
    return null;
  }

  return <Cmp data={data} {...attrs} />;
};

export default Widget;
