import React, { useImperativeHandle, forwardRef } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataContextProvider } from 'src/contexts/DataContext';

const Form = ({ fieldset, initialValues = {}, schema }, ref) => {
  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: schema ? yupResolver(schema) : undefined,
    // shouldUnregister: false,
    shouldUnregister: true,
    mode: 'onBlur'
  });

  useImperativeHandle(ref, () => formMethods);

  return (
    <DataContextProvider data={{ initialValues }}>
      <FormProvider {...formMethods}>{fieldset}</FormProvider>
    </DataContextProvider>
  );
};

export default forwardRef(Form);
