import React from 'react';

import { SvgIcon, makeStyles, Box } from '@material-ui/core';
import { Image as ImageIcon } from 'react-feather';
import { getValue } from 'src/dataUtils';

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: 4
  }
}));

const Thumb = ({ data, field, src, width, height }) => {
  const srcAttr = field || src;

  const classes = useStyles();

  const srcVal = getValue(data, srcAttr);

  return srcVal ? (
    <img
      alt=""
      src={srcVal}
      className={classes.image}
      style={{ width, height }}
    />
  ) : (
    <Box
      p={2}
      bgcolor="background.dark"
      width={width}
      height={height}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <SvgIcon>
        <ImageIcon />
      </SvgIcon>
    </Box>
  );
};

export default Thumb;
