import React from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';

import { IconButton, SvgIcon } from '@material-ui/core';

import {
  Edit as EditIcon,
  Trash2 as DeleteIcon,
  ArrowUp,
  ArrowDown
} from 'react-feather';

const EditActionBtn = ({ result, resourceSlug }) => {
  const { appName } = useParams();

  return (
    <IconButton
      component={RouterLink}
      to={`/${appName}/app/${resourceSlug}/edit/${result.id}`}
    >
      <SvgIcon fontSize="small">
        <EditIcon />
      </SvgIcon>
    </IconButton>
  );
};

const DeleteActionBtn = ({ result, onDelete }) => {
  return (
    <IconButton onClick={() => onDelete(result)}>
      <SvgIcon fontSize="small">
        <DeleteIcon />
      </SvgIcon>
    </IconButton>
  );
};

const MoveActionBtn = ({ action, result, handleRowAction }) => {
  return (
    <IconButton onClick={() => handleRowAction({ action, result })}>
      <SvgIcon fontSize="small">
        {action.direction === 'up' ? <ArrowUp /> : <ArrowDown />}
      </SvgIcon>
    </IconButton>
  );
};

const ROW_ACTIONS_PROPS = {
  move: MoveActionBtn,
  edit: EditActionBtn,
  delete: DeleteActionBtn
};

const RowAction = ({ action, result, context }) => {
  const ActionBtn = ROW_ACTIONS_PROPS[action.type];

  return <ActionBtn action={action} result={result} {...context} />;
};

const ResultActions = ({ result, rowActions, context }) => {
  if (rowActions === null || typeof rowActions === 'undefined') {
    rowActions = [
      {
        type: 'edit'
      },
      {
        type: 'delete'
      }
    ];
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      {rowActions.map((action, index) => {
        return (
          <RowAction
            action={action}
            result={result}
            context={context}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default ResultActions;
