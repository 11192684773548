import { useEffect, useState } from 'react';
import useAxios, { configure } from 'axios-hooks';
import useApiClient from 'src/hooks/useApiClient';
import { extract } from 'src/dataUtils';
import { expandParams } from 'src/dataUtils';

const useTreeView = ({ config }) => {
  const { client } = useApiClient();
  const [resultToDelete, setResultToDelete] = useState(null);

  configure({ axios: client });

  const [{ data: fetchResult, loading: fetchLoading }, executeFetch] = useAxios(
    {
      url: config.url
    },
    { manual: true }
  );

  const [{ loading: rowActionLoading }, executeRowAction] = useAxios(
    {},
    { manual: true }
  );

  const onDelete = result => {
    setResultToDelete(result);
  };

  const handleDeleteCancel = () => {
    setResultToDelete(null);
  };

  const handleDeleteItem = async resultToDelete => {
    await executeDelete({
      method: 'DELETE',
      url: `${config.url}/${resultToDelete.id}`
    });

    executeFetch({
      params: {
        ...(config.defaultParams || {})
      }
    });
  };

  const handleDeleteConfirm = async () => {
    await handleDeleteItem(resultToDelete);
    setResultToDelete(null);
  };

  const handleRowAction = async ({ action, result }) => {
    await executeRowAction(
      expandParams(action.request || {}, { id: result.id })
    );

    executeFetch({
      params: {
        ...(config.defaultParams || {})
      }
    });
  };

  const [, executeDelete] = useAxios(
    {
      url: config.url,
      method: 'DELETE'
    },
    { manual: true }
  );

  useEffect(() => {
    executeFetch({
      params: {
        ...(config.defaultParams || {})
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processedResults = !fetchResult
    ? []
    : config.response.dataPath
    ? extract(fetchResult, config.response.dataPath)
    : fetchResult;

  return {
    resultToDelete,
    handleDeleteConfirm,
    handleDeleteCancel,
    onDelete,
    rowActionLoading,
    handleRowAction,
    actions: {
      fetch: {
        execute: executeFetch,
        loading: fetchLoading,
        results: processedResults
      }
    }
  };
};

export default useTreeView;
