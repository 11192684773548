import React from 'react';

import { useParams } from 'react-router';
import { ResourceContextProvider } from '../../contexts/ResourceContext';

import ListView from 'src/generics/ListView';
import TreeView from 'src/generics/TreeView';
import DetailView from 'src/generics/DetailView';
import CreateView from 'src/generics/CreateView';
import EditView from 'src/generics/EditView';
import { getAppConfig } from 'src/generics/dataUtils';
import { useLocation } from 'react-router-dom';

const VIEW_MAP = {
  list: ListView,
  tree: TreeView,
  detail: DetailView,
  create: CreateView,
  edit: EditView
};

const View = () => {
  const { appName, resourceSlug, viewType, resourceId } = useParams();
  const location = useLocation();

  const appConfig = getAppConfig(appName);
  const viewConfig = appConfig.resources[resourceSlug][viewType];

  const ViewCmp = VIEW_MAP[viewType];

  return (
    <ResourceContextProvider
      resource={{ slug: resourceSlug, id: resourceId }}
      key={location.pathname}
    >
      <ViewCmp
        config={viewConfig}
        resourceId={resourceId}
        resourceSlug={resourceSlug}
      />
    </ResourceContextProvider>
  );
};

export default View;
